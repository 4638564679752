<template>
  <el-container>
    <el-main>
      <el-row :gutter="20" class="logo-el-row">
        <el-col :span="3"></el-col>
        <el-col :span="5">
          <el-card>
            <img :src="clubs[0] ? clubs[0].logoUrl : ''" alt="Logo" class="logo">
          </el-card>
        </el-col>
        <el-col :span="13">
          <div>
            <el-divider content-position="left">关于龙国台球协会</el-divider>
            <span>
              &nbsp;&nbsp;龙国台球协会，简称“龙国台协”或“中台协”，英文名称为Chinese Billiards
              & Snooker Association，缩写为CBSA，成立于1986年12月，是由各省、自治区、直辖市、各行业体协、解放军所属台球运动组织，
              企事业单位及其他合法的台球社会团体、俱乐部、从业人员、爱好者等自愿结成的全国性、行业性社会团体，是非营利性社会组织。
              协会的登记管理机关是民政部，党建领导机关是中央和国家机关工委，行业管理机关是国家体育总局。<br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;龙国台球协会是中华全国体育总会单位会员，总部设在北京，最高权力机构是全国委员会，执行机构是常务委员会领导下的秘书处。
              下设训练、竞赛、开发和新闻等4个专项委员会。协会主席是王涛。协会1988年加入国际台球联合会。1998年，协会编辑出版《中国台球》刊物。
              台球协会经常举办全国花式台球锦标赛、全国台球锦标赛、全国台球精英赛、全国台球争霸赛和全国台球南北明星对抗赛等。
            </span>
          </div>
        </el-col>
        <el-col :span="3"></el-col>
      </el-row>
      <el-row :gutter="20" class="club-el-row">
        <el-col :span="3">
          <el-input v-model="filterRegionText" placeholder="输入区域过滤" />
          <el-tree :data="regions" ref="regionTreeRef" :props="treeNodeProps" node-key="id" default-expand-all
            :expand-on-click-node="false" :highlight-current="true" :filter-node-method="filterNode"
            @node-click="handleNodeClick" />
        </el-col>
        <el-col :span="18">
          <el-row :gutter="10">
            <el-col v-for="club in clubInfos" :key="club.id" :span="3">
              <el-card @click="goToClub(club.clubid)" class="club-card">
                <img :src="club.logoUrl" alt="Club Logo" class="club-logo">
                <p>{{ club.name }}</p>
              </el-card>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="3"></el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
import axios from 'axios';
import { ElMessage } from 'element-plus';

export default {
  data() {
    return {
      clubs: [],
      regions: [],
      treeNodeProps: {
        children: 'children',
        label: 'label',
      },
      filterRegionText: ''
    };
  },

  created() {
    this.fetchClubs();
    this.fetchRegions();
  },
  computed: {
    clubInfos() {
      return this.clubs;
    },
  },
  mounted() {

  },
  watch: {
    filterRegionText(val) {
      this.$refs.regionTreeRef.filter(val);
    }
  },
  methods: {
    fetchClubs() {
      axios.post('api/clubInfo/getClubs')
        .then((response) => {
          this.clubs = response.data;
        })
        .catch(error => {
          this.showMessage('error', '俱乐部信息获取失败:' + error)
        });
    },
    goToClub(id) {
      this.$router.push(`/clubinfo/${id}`);
    },
    showMessage(type, msg) {
      ElMessage({
        message: msg,
        type: type,
      })
    },
    fetchRegions() {
      axios.get('api/clubInfo/GetExistsRegionTreeNode')
        .then((response) => {
          this.regions = response.data;
          if (this.regions.length > 0) {
            // this.defaultExpandedKeys=[this.regions[0].id]
          }
        })
        .catch(error => {
          this.showMessage('error', '区域部信息获取失败:' + error)
        });
    },
    filterNode(value, data) {
      if (!value) return true
      return data.label.includes(value)
    },
    handleNodeClick(node) {
      axios.post('api/clubInfo/GetClubsByRegion?regionCode=' + node.id)
        .then((response) => {
          this.clubs = response.data;
        })
        .catch(error => {
          this.showMessage('error', '俱乐部信息获取失败:' + error)
        });
    }
  }
};
</script>

<style>
.logo {
  height: 22vh;
}

.club-card {
  cursor: pointer;
  text-align: center;
}

.club-logo {
  width: 100%;
  height: 100px;
}
</style>

<style scoped>
.logo {
  height: 18vh;
}

.logo-el-row {
  height: 22vh;
  margin-top: 0px;
}

.club-el-row {
  height: 57vh;
}
</style>

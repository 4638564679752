<template>
    <el-container>
        <el-aside class="aside-left">
            <el-card>
                <el-image :src="club.logoUrl"></el-image>
                <p>{{ club.name }}</p>
                <p>{{ club.Contactinfo }}</p>
                <p>{{ club.Address }}</p>
                <p>{{ club.Introduction }}</p>
            </el-card>
            <el-card class="club-card">
                <el-descriptions title="简介">
                    <el-descriptions-item label="">&nbsp;&nbsp;{{ club.introduction }}
                    </el-descriptions-item>
                </el-descriptions>
            </el-card>
        </el-aside>
        <el-main>
            <el-row :gutter="10" class="player-el-row">
                <el-col :span="24">
                    <el-carousel :interval="3000" type="card" height="20vh">
                        <el-carousel-item v-for="player in clubPlayers" :key="player.id">
                            <el-image :src="player.avatarUrl" size="large" @click="showPlayerDetail(player)"></el-image>
                        </el-carousel-item>
                 </el-carousel>
                </el-col>
            </el-row>
            <el-row  class="introduction-el-row">
                <el-descriptions title="简介">
                    <el-descriptions-item label="">&nbsp;&nbsp;
                        {{currentPlayer.introduction}}
                    </el-descriptions-item>
                </el-descriptions>
            </el-row>
            <h1>会员比赛记录</h1>
            <el-row class="record-el-row">
                <el-table :data="progressInfos" style="width: 100%">
                    <el-table-column prop="stateName" label="状态" width="180" />
                    <el-table-column prop="roundBeginDate" label="时间" width="180" />
                    <el-table-column prop="participantAScore" label="胜负">
                        <template #default="scope">
                            <span>{{ scope.row.participantA.personInfo.name +' ' + scope.row.participantAScore+'vs'
                            + scope.row.participantBScore +' ' +scope.row.participantB.personInfo.name}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="matchName" label="赛事" width="180" />
                    <el-table-column prop="roundName" label="赛事类型" width="180" />
                </el-table>
            </el-row>
        </el-main>
    </el-container>
</template>

<script>
import axios from 'axios';
import { ElMessage } from 'element-plus';

export default {
    data() {
        return {
            clubInfo: {},
            players: [],
            progressInfos: [],
            currentPlayer:{}
        };
    },
    created() {
        this.fetchPlayers();
        this.getClubInfo();
    },
    computed: {
        clubPlayers() {
            return this.players;
        },
        club() {
            return this.clubInfo;
        }
    },
    methods: {
        fetchPlayers() {
            axios.post('api/playerInfo/getPlayersByClubId?clubid=' + this.$route.params.id)
                .then((response) => {
                    this.players = response.data;
                })
                .catch(error => {
                    this.showMessage('error', '球员信息获取失败:' + error)
                });
        },
        getClubInfo() {
            axios.post('api/clubInfo/getClub?id=' + this.$route.params.id)
                .then((response) => {
                    this.clubInfo = response.data;
                })
                .catch(error => {
                    this.showMessage('error', '球员信息获取失败:' + error)
                });
        },
        showPlayerDetail(p){
            this.currentPlayer=p;
            axios.post('api/ProgressInfo/GetProgressesByPsnId?psnId=' + p.personId)
                .then((response) => {
                    this.progressInfos = response.data;
                })
                .catch(error => {
                    this.showMessage('error', '球员信息获取失败:' + error)
                });
        },
        showMessage(type, msg) {
            ElMessage({
                message: msg,
                type: type,
            })
        }
    }
};
</script>

<style scoped>
.aside-left {
    width: 40%;
    height: 100vh;
}

.h-font-left{
    text-align: left;
}
.player-el-row{
    height: 40vh;
}
.introduction-el-row{
    height: 8vh;
}
.el-row{
    margin-top: 0;
}
</style>

<template>
    <div>
        <el-row style="height: auto;">
            <el-col :span="col1"></el-col>
            <el-col :span="col2">
                <div class="tableDiv">
                    <el-table :data="players">
                    <el-table-column prop="nickName" label="昵称"></el-table-column>
                    <el-table-column prop="grade" label="等级"></el-table-column>
                    <el-table-column prop="address" label="区域"></el-table-column>
                    <el-table-column prop="phone" label="手机号"></el-table-column>
                    <el-table-column prop="matchNum" label="比赛场次"></el-table-column>
                    <el-table-column prop="cumulativeScore" label="积分"></el-table-column>
                    <el-table-column prop="hitNum" label="热度"></el-table-column>
                    <el-table-column align="right">
                        <template #default="scope">
                            <el-button  color="#626aef"
                                @click="showPersonelInfo(scope.$index, scope.row)">风采</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                </div>
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    v-model:currentPage="currentPage" :page-sizes="[10, 20, 30, 40]" v-model:page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </el-col>
            <el-col :span="col3">
                <div v-if="isShowpersoninfo">
                    <el-divider content-position="center">{{currentPlayer.nickName}}的风采</el-divider>
                <div>
                    <el-carousel :interval="3000" direction="vertical" height="40vh">
                        <el-carousel-item v-for="file in imageList" :key="file.id">
                            <el-image :src='file.url' />
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <el-divider content-position="center">赛程信息</el-divider>
                <div class="progressinfoDiv">
                    <el-table :data="progressInfos" style="width: 100%">
                        <el-table-column prop="stateName" label="状态" width="180" />
                        <el-table-column prop="roundBeginDate" label="时间" width="180" />
                        <el-table-column prop="participantAScore" label="胜负">
                            <template #default="scope">
                                <span>{{ scope.row.participantA.personInfo.name + ' ' + scope.row.participantAScore + 'vs'
                                    + scope.row.participantBScore + ' ' + scope.row.participantB.personInfo.name}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="matchName" label="赛事" width="180" />
                        <el-table-column prop="roundName" label="赛事类型" width="180" />
                    </el-table>
                </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import axios from 'axios';
import { ElMessage } from 'element-plus';

export default {
    name: 'ParticipantManagement',
    data() {
        return {
            players: [],
            imageList: [],
            currentPage: 1,
            pageSize: 10,
            total: 1000,
            col1:4,
            col2:16,
            col3:4,
            isShowpersoninfo:false,
            currentPlayer:{},
            progressInfos: []
        };
    },
    created() {
        this.fetchPlayers();
    },
    methods: {
        fetchPlayers() {
            axios.post('api/PlayerInfo/GetPlayersByPage', { currentPage: this.currentPage, pageSize: this.pageSize })
                .then(response => {
                    this.total = response.data.total;
                    this.players = response.data.content;
                })
                .catch(error => {
                    this.showMessage('error', '获取报名列表信息列表失败:' + error)
                });
        },
        async showPersonelInfo(index, row) {
            this.currentPlayer = row;
            this.col1=1;
            this.col2=11;
            this.col3=12;
            this.isShowpersoninfo = true;
            await this.getImageList();
            //点击量+1
            this.currentPlayer;
            await axios.post('/api/PlayerManagement/HitPlayer',this.currentPlayer);

            const response = await axios.post('api/ProgressInfo/GetProgressesByPsnId?psnId=' + row.personId);
            this.progressInfos = response.data;
        },
        async getImageList() {
            const response = await axios.get('/api/personManagement/getImages?personid=' + this.currentPlayer.personId);
            this.imageList = response.data;
        },
        handleSizeChange(val) {
            this.pageSize = val;
            // 请求后端数据，根据 pageSize 和 currentPage 获取数据
            this.fetchPlayers();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            // 请求后端数据，根据 pageSize 和 currentPage 获取数据
            this.fetchPlayers();
        },
        showMessage(type, msg) {
            ElMessage({
                message: msg,
                type: type,
            })
        }
    }
}
</script>
<style>
.tableDiv{
    min-height: 70vh;
}
</style>
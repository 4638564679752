<template>
    <el-container>
      <el-header>
        <img src="@/assets/logo.png" alt="Logo" class="logo">
      </el-header>
      <el-container>
        <el-aside width="200px">
          <el-menu>
            <el-menu-item 
              v-for="player in players" 
              :key="player.id" 
              @click="selectPlayer(player)"
            >
              <el-avatar :src="player.avatar" size="large"></el-avatar>
              {{ player.name }}
            </el-menu-item>
          </el-menu>
        </el-aside>
        <el-main>
          <div v-if="selectedPlayer">
            <h2>{{ selectedPlayer.name }}</h2>
            <p><strong>性别：</strong> {{ selectedPlayer.gender }}</p>
            <p><strong>联系信息：</strong> {{ selectedPlayer.contact }}</p>
            <p><strong>所属俱乐部：</strong> {{ selectedPlayer.club }}</p>
            <p><strong>简介：</strong> {{ selectedPlayer.description }}</p>
          </div>
          <div v-else>
            <p>请选择一位球员查看详情</p>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </template>
  
  <script>
  export default {
    data() {
      return {
        players: [
          {
            id: 1,
            name: '球员1',
            gender: '男',
            avatar: 'avatar1.png',
            contact: 'contact1@example.com',
            club: '俱乐部1',
            description: '这是球员1的简介。',
          },
          {
            id: 2,
            name: '球员2',
            gender: '女',
            avatar: 'avatar2.png',
            contact: 'contact2@example.com',
            club: '俱乐部2',
            description: '这是球员2的简介。',
          },
          // 添加更多球员信息
        ],
        selectedPlayer: null,
      };
    },
    methods: {
      selectPlayer(player) {
        this.selectedPlayer = player;
      },
    },
  };
  </script>
  
  <style>
  .logo {
    height: 50px;
  }
  
  .el-aside {
    background-color: #f0f2f5;
    padding: 20px;
  }
  
  .el-menu-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .el-avatar {
    margin-right: 10px;
  }
  
  .el-main {
    padding: 20px;
  }
  </style>
  
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementPlus from 'element-plus';
import 'element-plus/theme-chalk/index.css';
import 'element-plus/theme-chalk/dark/css-vars.css';
import axios from 'axios';
import { ElMessage } from 'element-plus';

// 设置 Axios 全局默认值
axios.defaults.baseURL = 'http://47.108.198.144:8089/';
// axios.defaults.baseURL = 'http://localhost:5288/';

//设置 Axios 请求拦截器,自动添加 JWT 令牌到请求头中
axios.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
      }else{
        // router.push(`/`);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

//设置 Axios 响应拦截器
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      // 令牌过期，需要重新登录或刷新令牌
      // 在这里你可以执行以下操作：
      // 1. 重新获取新的访问令牌（使用刷新令牌或其他方式）
      // 2. 更新本地存储的令牌
      // 3. 重新发送之前的请求
      // 4. 显示一个友好的提示给用户
      ElMessage.error('令牌过期或未登录。请重新登录');
      localStorage.clear();
      router.push(`/`);
    }
    return Promise.reject(error);
  }
);

// createApp(App).mount('#app')

const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);

app.mount('#app');


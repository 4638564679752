<template>
    <div>
        <el-row style="height: auto;">
            <el-col :span="2"></el-col>
            <el-col :span="20">
                <el-table :data="participants">
                    <el-table-column prop="personInfo.name" label="姓名"></el-table-column>
                    <el-table-column prop="personInfo.gender" label="性别">
                        <template #default="scope">
                            <span>{{ scope.row.personInfo.gender == 'F' ? '女' : '男' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="personInfo.idNo" label="身份证号"></el-table-column>
                    <el-table-column prop="personInfo.contactinfo" label="联系信息"></el-table-column>
                    <el-table-column prop="participantInfo.signinState" label="签到状态">
                        <template #default="scope">
                            <span>{{ scope.row.participantInfo.signinState == '1' ? '已签到' : '未签到' }}</span>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column>
                        <template #header>
                            <el-button type="primary" @click="showAddParticipantDialog">添加参赛者</el-button>
                        </template>
                        <template #default="scope">
                            <el-button @click="editParticipant(scope.row)" size="small">编辑</el-button>
                            <el-button @click="signin(scope.row)" size="small">签到</el-button>
                        </template>
                    </el-table-column> -->
                </el-table>
            </el-col>
            <el-col :span="2"></el-col>
        </el-row>

        <el-row>
            <el-col :span="2"></el-col>
            <el-col :span="20">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    v-model:currentPage="currentPage" :page-sizes="[10, 20, 30, 40]" v-model:page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </el-col>
            <el-col :span="2"></el-col>
        </el-row>
        <el-dialog v-model="addParticipantDialogVisible" title="添加参赛者">
            <el-form :model="currentParticipant">
                <el-form-item label="姓名">
                    <el-input v-model="currentParticipant.name"></el-input>
                </el-form-item>
                <el-form-item label="性别">
                    <el-select v-model="currentParticipant.gender" class="m-2" placeholder="Select" size="large">
                        <el-option v-for="item in genderDicList" :key="item.value" :label="item.name"
                            :value="item.value" />
                    </el-select>
                </el-form-item>
                <el-form-item label="身份证号">
                    <el-input v-model="currentParticipant.idNo"></el-input>
                </el-form-item>
                <el-form-item label="联系信息">
                    <el-input v-model="currentParticipant.contactinfo"></el-input>
                </el-form-item>
            </el-form>
            <span class="dialog-footer">
                <slot name="footer">
                    <el-button @click="cancelEditParticipant">取消</el-button>
                    <el-button type="primary" @click="saveParticipant">确定</el-button>
                </slot>

            </span>
        </el-dialog>
    </div>
</template>

<script>
import axios from 'axios';
import { ElMessage } from 'element-plus';

export default {
    name: 'ParticipantManagement',
    data() {
        return {
            participants: [],
            addParticipantDialogVisible: false,
            currentParticipant: {
                participantid: '',
                matchid: '',
            },
            preMatchInfos: [],
            genderDicList: [{ value: "M", name: "男" }, { value: "F", name: "女" }],
            currentPage: 1,
            pageSize: 10,
            total: 1000
        };
    },
    created() {
        this.fetchParticipants();
    },
    methods: {
        fetchParticipants() {
            axios.post('api/participantManagement/GetParticipantsByPage', { currentPage: this.currentPage, pageSize: this.pageSize })
                .then(response => {
                    this.total = response.data.total;
                    this.participants = response.data.content;
                })
                .catch(error => {
                    this.showMessage('error', '获取报名列表信息列表失败:' + error)
                });
            // axios.post('api/participantManagement/GetParticipants')
            //     .then(response => {
            //         this.participants = response.data;
            //     })
            //     .catch(error => {
            //         this.showMessage('error', '获取报名列表信息列表失败:' + error)
            //     });
        },
        showAddParticipantDialog() {
            this.addParticipantDialogVisible = true;
        },
        saveParticipant() {
            if (this.currentParticipant.participantid) {
                this.modeifyParticipant();
            } else {
                this.addParticipant();
            }

        },
        modeifyParticipant() {
            axios.post('api/participantManagement/modifyParticipant', this.currentParticipant)
                .then(() => {
                    this.fetchParticipants();
                    this.addParticipantDialogVisible = false;
                    this.currentParticipant = {};
                })
                .catch(error => {
                    this.showMessage('error', '更新参赛者信息失败:' + error)
                });
        },
        addParticipant() {
            axios.post('api/participantManagement/addParticipant', this.currentParticipant)
                .then(() => {
                    this.fetchParticipants();
                    this.addParticipantDialogVisible = false;
                    this.currentParticipant = {};
                })
                .catch(error => {
                    this.showMessage('error', '报名信息添加失败:' + error)
                });

        },
        editParticipant(participant) {
            this.currentParticipant = { ...participant };
            this.addParticipantDialogVisible = true;
        },
        cancelEditParticipant() {
            this.currentParticipant = {};
            this.addParticipantDialogVisible = false;
        },
        signin(participant) {
            this.currentParticipant = { ...participant };
            this.currentParticipant.participant.signinState = "1";
            this.currentParticipant.participan.signinTime = new Date().toISOString();
            this.modeifyParticipant();
        },
        handleSizeChange(val) {
            this.pageSize = val;
            // 请求后端数据，根据 pageSize 和 currentPage 获取数据
            this.fetchParticipants();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            // 请求后端数据，根据 pageSize 和 currentPage 获取数据
            this.fetchParticipants();
        },
        showMessage(type, msg) {
            ElMessage({
                message: msg,
                type: type,
            })
        }
    }
}
</script>

<template>
    <el-row :gutter="20">
        <el-col :span="2"></el-col>
        <el-col :span="6">
            <div class="avatardiv">
                <el-avatar shape="circle" :size="110" fit="fill" :src="avatarUrl" />
                <div>
                    <video controls width="300">
                        <source src="your_video_url_here.mp4" type="video/mp4">
                        <!-- 添加其他格式的视频源，例如 WebM 或 Ogg -->
                        <!-- <source src="your_video_url_here.webm" type="video/webm"> -->
                        <!-- <source src="your_video_url_here.ogv" type="video/ogg"> -->
                        您的浏览器不支持视频播放。
                    </video>
                </div>
            </div>
            <div class="descriptionsDiv">
                <el-descriptions title="个人信息" direction="vertical" :column="4" border>
                    <el-descriptions-item label="昵称">{{ player.nickName }}</el-descriptions-item>
                    <el-descriptions-item label="姓名">{{ player.name }}</el-descriptions-item>
                    <el-descriptions-item label="性别">{{ player.gender == 'F' ? '女' : '男' }}</el-descriptions-item>
                    <el-descriptions-item label="身份证">{{ player.idNo }}</el-descriptions-item>
                    <el-descriptions-item label="手机号">{{ player.phone }}</el-descriptions-item>
                    <el-descriptions-item label="等级">{{ player.grade }}</el-descriptions-item>
                    <el-descriptions-item label="地址" :span="2">{{ player.address }}</el-descriptions-item>
                    <el-descriptions-item label="俱乐部" :span="2">
                        <el-tag size="small">{{ player.clubName }}</el-tag>
                    </el-descriptions-item>
                    <el-descriptions-item label="联系信息" :span="2">{{ player.contactinfo }}</el-descriptions-item>
                    <el-descriptions-item label="简介" :span="4">
                        {{ player.introduction }}
                    </el-descriptions-item>
                    <el-descriptions-item label="操作">
                        <el-button @click="editing = !editing">{{ editing ? '取消' : '编辑' }}</el-button>
                    </el-descriptions-item>
                </el-descriptions>
            </div>
        </el-col>
        <el-col :span="14">
            <div v-if="editing">
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-divider content-position="left">个人信息</el-divider>
                        <div class="player-form">
                            <el-form :model="player" :rules="rules" ref="playerFormRef" label-width="80px">
                                <el-form-item label="昵称" prop='nickName'>
                                    <el-input v-model="player.nickName"></el-input>
                                </el-form-item>
                                <el-form-item label="姓名" prop='name'>
                                    <el-input v-model="player.name"></el-input>
                                </el-form-item>
                                <el-form-item label="身份证号" prop='idNo'>
                                    <el-input v-model="player.idNo"></el-input>
                                </el-form-item>
                                <el-form-item label="性别">
                                    <el-radio-group v-model="player.gender">
                                        <el-radio value="M">男</el-radio>
                                        <el-radio value="F">女</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item label="省">
                                    <el-select v-model="player.province" filterable remote clearable
                                        placeholder="输入搜索选择" :remote-method="provinceSearch" :loading="provinceLoading"
                                        @change="provinceSelectChange">
                                        <el-option v-for="item in searchedProvinces" :key="item.regionCode"
                                            :label="item.regionName" :value="item.regionCode" />
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="市">
                                    <el-select v-model="player.city" filterable remote clearable placeholder="输入搜索选择"
                                        :remote-method="citySearch" :loading="cityLoading" @change="citySelectChange">
                                        <el-option v-for="item in searchedCities" :key="item.regionCode"
                                            :label="item.regionName" :value="item.regionCode" />
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="县">
                                    <el-select v-model="player.county" filterable remote clearable placeholder="输入搜索选择"
                                        :remote-method="countySearch" :loading="countyLoading">
                                        <el-option v-for="item in searchedCounties" :key="item.regionCode"
                                            :label="item.regionName" :value="item.regionCode" />
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="手机号" prop="phone">
                                    <el-input v-model="player.phone"></el-input>
                                </el-form-item>
                                <el-form-item label="联系信息">
                                    <el-input v-model="player.contactinfo"></el-input>
                                </el-form-item>
                                <el-form-item label="球员等级">
                                    <el-select v-model="player.grade" placeholder="请选择">
                                        <el-option label="A" value="A"></el-option>
                                        <el-option label="B" value="B"></el-option>
                                        <el-option label="C" value="C"></el-option>
                                        <el-option label="D" value="D"></el-option>
                                        <el-option label="E" value="E"></el-option>
                                        <el-option label="F" value="F"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="简介">
                                    <el-input v-model="player.introduction" type="textarea" />
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="handleSubmit">确定</el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <el-divider content-position="left">头像</el-divider>
                        <el-upload v-model:file-list="avatarList" :action="avatarActionUrl" list-type="picture-card"
                            :limit="1" :on-preview="handlePictureCardPreview" :on-remove="handleRemoveAvatar">
                            <el-icon>
                                <Plus />
                            </el-icon>
                        </el-upload>
                    </el-col>
                    <el-col :span="12">
                        <el-divider content-position="left">风采照片</el-divider>
                        <el-upload v-model:file-list="imageList" :action="actionUrl" list-type="picture-card"
                            :limit="10" :on-preview="handlePictureCardPreview" :on-remove="handleRemove">
                            <el-icon>
                                <Plus />
                            </el-icon>
                        </el-upload>
                        <el-dialog v-model="dialogVisible">
                            <img w-full :src="dialogImageUrl" alt="预览图片" />
                        </el-dialog>
                    </el-col>
                </el-row>
            </div>
            <div v-else>
                <el-divider content-position="center">风采照片</el-divider>
                <div class="imagesDiv">
                    <el-carousel :interval="3000" type="card" height="40vh">
                        <el-carousel-item v-for="img in imageList" :key="img.id">
                            <el-image :src='img.url' />
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <el-divider content-position="center">赛程信息</el-divider>
                <div class="progressinfoDiv">
                    <el-table :data="progressInfos" style="width: 100%">
                        <el-table-column prop="stateName" label="状态" width="180" />
                        <el-table-column prop="roundBeginDate" label="时间" width="180" />
                        <el-table-column prop="participantAScore" label="胜负">
                            <template #default="scope">
                                <span>{{ scope.row.participantA.personInfo.name + ' ' + scope.row.participantAScore + 'vs'
                                    + scope.row.participantBScore + ' ' + scope.row.participantB.personInfo.name}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="matchName" label="赛事" width="180" />
                        <el-table-column prop="roundName" label="赛事类型" width="180" />
                    </el-table>
                </div>
            </div>
        </el-col>
        <el-col :span="2"></el-col>
    </el-row>
</template>

<script>
import { ElMessage } from 'element-plus';
import axios from 'axios';
import { Plus } from '@element-plus/icons-vue'

export default {
    components: {
        Plus: Plus
    },
    data() {
        return {
            player: {},
            user: {},
            rules: {
                nickName: [{ required: true, message: '请输入昵称', trigger: 'blur' }],
                name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
                idNo: [{ required: true, message: '请输入身份证号', trigger: 'blur' }],
                phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
            },
            provinces: [],
            searchedProvinces: [],
            provinceLoading: false,
            cities: [],
            searchedCities: [],
            cityLoading: false,
            counties: [],
            searchedCounties: [],
            countyLoading: false,
            editing: false,
            imageList: [],
            avatarList: [],
            dialogImageUrl: '',
            dialogVisible: false,
            avatarUrl: '',
            actionUrl: '',
            avatarActionUrl: '',
            progressInfos: [],
        };
    },
    created() {
        var userstr = localStorage.getItem('user');
        this.user = JSON.parse(userstr);
    },
    mounted() {
        this.getPersonelInfo();
        this.fetchProvince();

    },
    watch: {
        player(val) {
            if (val && val.personId) {
                this.fetchProgressInfos();
            }
        }
    },
    methods: {
        async getPersonelInfo() {
            const response = await axios.post('api/playerInfo/getPlayerByUser?id=' + this.user.userid);
            this.player = response.data;
            await this.getImageList();
            await this.getAvatarList();
            this.avatarUrl = axios.defaults.baseURL + 'api/PersonManagement/GetAvatar?personid=' + this.player.personId;
            this.actionUrl = axios.defaults.baseURL + 'api/PersonManagement/uploadImage?personid=' + this.player.personId;
            this.avatarActionUrl = axios.defaults.baseURL + 'api/PersonManagement/UploadAvatar?personid=' + this.player.personId;
        },
        async getImageList() {
            const response = await axios.get('/api/personManagement/getImages?personid=' + this.player.personId);
            this.imageList = response.data;
        },
        async getAvatarList() {
            const response = await axios.get('/api/personManagement/GetAvatars?personid=' + this.player.personId);
            this.avatarList = response.data;
        },
        fetchProvince() {
            axios.get('api/baseData/getProvinces')
                .then((response) => {
                    this.provinces = response.data;
                })
                .catch(error => {
                    ElMessage.error('获取省份失败:' + error);
                });
        },
        async fetchProgressInfos() {

            const response = await axios.post('api/ProgressInfo/GetProgressesByPsnId?psnId=' + this.player.personId);
            this.progressInfos = response.data;
        },
        provinceSearch(query) {
            if (query) {
                this.provinceLoading = true
                setTimeout(() => {
                    this.provinceLoading = false;
                    this.searchedProvinces = this.provinces.filter((item) => {
                        return item.regionName.toLowerCase().includes(query.toLowerCase()) || item.pyCode.toLowerCase().includes(query.toLowerCase())
                    })
                }, 200)
            } else {
                this.searchedProvinces = []
            }
        },
        provinceSelectChange(regionCode) {
            axios.get('api/baseData/getReginByParent?parentCode=' + regionCode)
                .then((response) => {
                    this.cities = response.data;
                    this.searchedcities = response.data;
                })
                .catch(error => {
                    ElMessage.error('获取市失败:' + error);
                });
        },
        citySearch(query) {
            if (query) {
                this.cityLoading = true
                setTimeout(() => {
                    this.cityLoading = false;
                    this.searchedCities = this.cities.filter((item) => {
                        return item.regionName.toLowerCase().includes(query.toLowerCase()) || item.pyCode.toLowerCase().includes(query.toLowerCase())
                    })
                }, 200)
            } else {
                this.searchedCities = []
            }
        },
        citySelectChange(regionCode) {
            axios.get('api/baseData/getReginByParent?parentCode=' + regionCode)
                .then((response) => {
                    this.counties = response.data;
                    this.searchedCounties = response.data;
                })
                .catch(error => {
                    ElMessage.error('获取县失败:' + error);
                });
        },
        countySearch(query) {
            if (query) {
                this.provinceLoading = true
                setTimeout(() => {
                    this.countyLoading = false;
                    this.searchedCounties = this.counties.filter((item) => {
                        return item.regionName.toLowerCase().includes(query.toLowerCase()) || item.pyCode.toLowerCase().includes(query.toLowerCase())
                    })
                }, 200)
            } else {
                this.searchedCounties = []
            }
        },
        async handleSubmit() {
            this.$refs.playerFormRef.validate(async (valid) => {
                if (valid) {
                    try {
                        const response = await axios.post('api/PlayerManagement/modifyPlayer', this.player);
                        const result = response.data;
                        if (result) {
                            ElMessage.info('信息修改成功');
                            this.$router.go(0);
                        } else {
                            ElMessage.error('修改失败:' + result.errMsg);
                        }
                    } catch (error) {
                        ElMessage.error('提交信息失败:' + error);
                    }
                }
            });
        },
        async handleRemove(uploadFile) {
            const response = await axios.post('api/PersonManagement/RemoveImage?id=' + uploadFile.id);
            const result = response.data;
            if (result > 0) {
                ElMessage.success('删除成功');
            }
        },
        handlePictureCardPreview(uploadFile) {
            this.dialogImageUrl = uploadFile.url
            this.dialogVisible = true
        },
        async handleRemoveAvatar(uploadFile) {
            const response = await axios.post('api/PersonManagement/RemoveAvatar?id=' + uploadFile.id);
            const result = response.data;
            if (result > 0) {
                ElMessage.success('删除成功');
            }
        },
    }
};
</script>

<style>
.avatardiv {
    display: flex;
    justify-content: center;
    /* 水平居中 */
    align-items: center;
    /* 垂直居中 */
}

.imagesDiv {
    margin-top: 1vh;
}

.avatardiv .title {
    margin-bottom: 10px;
    font-size: 14px;
    color: var(--el-text-color-secondary);
}

.descriptionsDiv {
    background-color: #9CD7FF;
}

.descriptionsDiv table {
    background-color: #9CD7FF;
}

.progressinfoDiv .cell {
    background-color: #9CD7FF;
}
</style>
<template>
  <div id="app">
    <el-container class="wrapper">
      <el-header class="el-header0">
        <div class="logo-container">
          <img src="@/assets/logo.png" alt="Logo" class="logo" />
       </div>
        <div class="menu-container">
          <!-- 菜单组件，根据登录状态刷新 -->
          <MenuComponent :user="user"/>
        </div>
        <div class="login-container" v-if="!user">
          <el-button @click="showLogin">登录</el-button><el-button @click="register">注册</el-button>
        </div>
        <div class="user-info-container" v-else>
          <span class="welcome-message">欢迎您, {{ user.description }}</span>
          <el-button @click="persenelInfo">个人</el-button>
          <el-button @click="loginOut">注销</el-button>
        </div>


        <LoginDialog :isLoginDialogVisible="isLoginDialogVisible" @login-success="handleLoginSuccess"
          @update-visible="updateLoginDialogVisible" />
      </el-header>
      <el-main id="main-content" class="main-content">
        <!-- <el-header class="el-header1">
          <div>
            <el-breadcrumb separator="|" class="breadcrumb">
              <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item>{{ selectedMenuItemText }}</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
        </el-header> -->
        <router-view></router-view>
      </el-main>
      <el-footer class="footer">
        welln @ 2024
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import LoginDialog from './components/LoginDialog.vue';
import MenuComponent from './components/MenuComponent.vue';
// import BreadcrumbNav from '@/components/BreadcrumbNav.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  components: {
    LoginDialog,
    MenuComponent,
    // BreadcrumbNav
  },
  data() {
    return {
      isLoginDialogVisible: false,
      user: null
    };
  },
  created() {
    const userData = localStorage.getItem('user');
    if (userData) {
      this.user = JSON.parse(userData);
    }
  }, 
  computed: {
    ...mapGetters(['getSelectedMenuItemText']),
    selectedMenuItemText() {
      return this.getSelectedMenuItemText;
    }
  },
  methods: {
    showLogin() {
      this.isLoginDialogVisible = true;
    },
    loginOut(){
      localStorage.clear();
      this.user=null;
      this.$router.push('/');
    },
    updateLoginDialogVisible(newValue) {
      this.isLoginDialogVisible = newValue;
    },
    handleLoginSuccess(loggedInUser) {
      this.user = loggedInUser;
    },
    register(){
      this.$router.push('/playerRegister');
    },
    persenelInfo(){
      this.$router.push('/personelInfo');
    },
    handleSelect(key, keyPath) {
      switch (key) {
        case '1':
          this.$router.push('/');
          console.log(keyPath);
          break;
        case '2':
          this.$router.push('/participantManagement');
          break;
        case '3':
          this.$router.push('/playerManagement');
          break;
        case '4':
          this.$router.push('/clubManagement');
          break;
        case '5':
          this.$router.push('/matchManagement');
          break;
        case '6':
          this.$router.push('/matchManagement');
          break;
      }
    }
  }
}
</script>

<style>
body {
  margin: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app {
  text-align: center;
  color: #8dafa5;
  background-color: #FFF3E8;
  background-image: url('@/assets/bgd1.jpg');
  background-repeat: no-repeat; /* 可选，设置图片不重复 */
  background-size: cover; /* 可选，设置图片铺满整个屏幕 */
}

.wrapper {
  position: relative;
  min-height: 100vh;
  /* 确保内容区域至少占满整个视口高度 */
}

/* .footer {
  position: absolute;
  bottom: 0;
  width: 100%;
} */
</style>

<style scoped>
.el-header0 {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #9CD7FF;
}

.el-header1 {
  padding: 0;
  background-color: #FCC8C0;
}

.top-menu {
  background-color: #030d1b;
  display: flex;
  align-items: center;
  /* Center items vertically */
}

.top-menu :deep(li) {
  color: white;
  font-size: medium;
}

.menu-divider {
  height: 30px;
  margin: 0 10px;
}

.header {
  background-color: #22262c;
  padding: 0;
  /* 移除默认填充 */
}

.main-content {
  padding: 0;
  /* 移除默认填充 */
}

.breadcrumb {
  /* background-color: #1d2229; */
  line-height: 60px;
  margin: 0;
}

.el-breadcrumb {
  margin: 0;
}

.breadcrumb :deep(span) {
  color: white;
  /* 字体颜色 */
  font-size: 18px;
  /* 字体大小 */
}

#main-content .el-header1 div {
  width: 90%;
  /* 设置宽度为父元素的80% */
  margin: auto;
  /* 水平居中对齐 */
}

#main-content el-main {
  padding: 0;
}

.menu-container {
  flex-grow: 1;
}

.login-container {
  margin-left: auto;
}

.user-info-container {
  margin-right: 20px;
}

.welcome-message {
  font-size: 16px;
  color: #333;
  margin-right: 10px;
}

.login-container {
  margin-left: auto;
}
</style>

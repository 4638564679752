<template>
  <el-container>
    <el-main>
      <el-row :gutter="20" class="logo-el-row">
        <el-col :span="5"></el-col>
        <el-col :span="5">
          <el-card class="referee-card">
            <el-image :src="mainReferee && mainReferee.refereeInfo ? mainReferee.personInfo.avatarUrl : ''" alt="主裁判"
              class="mainReferee" @click="showRefereeDetail(mainReferee)"/>
          </el-card>
        </el-col>
        <el-col :span="2"></el-col>
        <el-col :span="10">
          <div>
            <el-divider content-position="left">关于裁判团</el-divider>
            <span>
              &nbsp;&nbsp;一支享有盛誉的专业台球裁判团队，汇聚了一批经验丰富、技艺精湛的裁判员。我们致力于为各类台球比赛提供高水准的裁判服务，确保比赛的公平、公正进行。
              裁判团队由多名经验丰富、备受尊敬的裁判员组成，他们在国际和国内台球赛事中积累了丰富的裁判经验。团队成员不仅熟悉各种台球比赛规则，还具备扎实的裁判技能和专业精神。
              裁判团队可以为各类台球比赛提供裁判服务，包括斯诺克、美式台球、英式台球等多种台球项目。无论是业余比赛、职业比赛还是大型赛事，我们都能够提供高水准的裁判服务。
              注重团队合作和专业精神，以确保比赛的顺利进行。我们始终秉持公正、透明和严谨的态度，力求为比赛提供最好的裁判服务。
            </span>
          </div>
        </el-col>
        <el-col :span="2"></el-col>
      </el-row>
      <el-row :gutter="20" class="referee-el-row">
        <el-col :span="1"></el-col>
        <el-col :span="11">
          <el-row :gutter="20">
            <el-col v-for="referee in otherReferees" :key="referee.id" :span="4" style="margin-bottom: 20px;">
              <el-card @click="showRefereeDetail(referee)" class="referee-card">
                <el-image :src="referee.personInfo.avatarUrl" alt="裁判头像" />
                <p>{{ referee.name }}</p>
              </el-card>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="10">
          <el-row class="introduction-el-row">
            <el-col :span="24">
              <el-descriptions :title="currentReferee && currentReferee.personInfo? currentReferee.personInfo.name+' 简介':''+' 简介'">
                <el-descriptions-item label="">&nbsp;&nbsp;{{ currentReferee && currentReferee.refereeInfo ?
                  currentReferee.refereeInfo.introduction : "" }}
                </el-descriptions-item>
              </el-descriptions>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-divider content-position="left">执裁记录</el-divider>
              <el-table :data="tableData" style="width: 100%">
                <el-table-column prop="state" label="状态" width="180" />
                <el-table-column prop="date" label="时间" width="180" />
                <el-table-column prop="name" label="赛事" width="180" />
                <el-table-column prop="type" label="赛事类型" width="180" />
              </el-table>
            </el-col>

          </el-row>
          <el-col :span="2"></el-col>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
import axios from 'axios';
import { ElMessage } from 'element-plus';

export default {
  data() {
    return {
      referees: [],
      mainReferee: {},
      otherReferees: [],
      currentReferee: {},
      tableData: [{
        state: "已结束",
        date: "2024-06-01",
        df: "1:3",
        name: "2024职业联赛",
        type: "秋季赛季后赛"
      }, {
        state: "已结束",
        date: "2024-06-01",
        df: "1:3",
        name: "2024职业联赛",
        type: "秋季赛季后赛"
      }],
    };
  },
  created() {
    this.fetchReferees();
  },
  computed: {
    refereeInfos() {
      return this.referees;
    },
  },
  methods: {
    fetchReferees() {
      axios.post('api/refereeInfo/getReferees')
        .then((response) => {
          this.referees = response.data;
          this.otherReferees = this.referees.filter((item) => {
            return item.refereeInfo.isMain != "1";
          });
          const mainRefes = this.referees.filter((item) => {
            return item.refereeInfo.isMain == "1";
          });
          if (mainRefes.length > 0) {
            this.mainReferee = mainRefes[0];
          }
        })
        .catch(error => {
          this.showMessage('error', '俱乐部信息获取失败:' + error)
        });
    },
    showRefereeDetail(referee) {
      this.currentReferee = referee;
    },
    showMessage(type, msg) {
      ElMessage({
        message: msg,
        type: type,
      })
    }
  }
};
</script>

<style>
.logo {
  width: 100%;
  height: 50px;
}

.referee-card {
  cursor: pointer;
  text-align: center;
}

.avtart-div {
  display: block;
  width: 200px;
  height: 200px;
  float: left;
  margin-bottom: 20px;
}
</style>

<style scoped>
.mainReferee {
  height: 18vh;
}

.logo-el-row {
  height: 23vh;
  margin-top: 0px;
}

.referee-el-row {
  height: 50vh;
}

.introduction-el-row {
  height: 8vh;
}
</style>
<template>
    <el-row>
        <el-col :span="2">
        </el-col>
        <el-col :span="6">
            <el-menu :default-openeds="openeds" class="el-menu-vertical-demo" background-color="#545c64"
                text-color="#fff" active-text-color="#ffd04b" @open="handleOpen" 
                @close="handleClose">
                <el-sub-menu v-for="matchAndRound in matchAndRounds" :key="matchAndRound.matchInfo.matchid"
                    :index="matchAndRound.matchInfo.matchid">
                    <template #title>
                        <i class="el-icon-location"></i>
                        <span>{{ matchAndRound.matchInfo.title }}</span>
                    </template>
                    <el-menu-item v-for="round in matchAndRound.rounds" :key="round.roundid" :index="round.roundid"
                        @click="showProgressInfo(round.roundid)">
                        {{ round.roundName }}
                    </el-menu-item>
                </el-sub-menu>
            </el-menu>
        </el-col>
        <el-col :span="14">
            <el-header style="background-color: #f56c6c; color: #fff; line-height: 60px; text-align: center">
                赛程信息
            </el-header>
            <el-main>
                <el-table :data="progresses" class="el-table">
                    <el-table-column label="状态" prop="stateName" />
                    <el-table-column label="赛事名称" prop="matchName" />
                    <el-table-column label="赛事阶段" prop="roundName" />
                    <el-table-column label="赛事时间" prop="roundBeginDate" />
                    <el-table-column label="对阵情况" width="180">
                        <template #default="scope">
                            <el-tag>{{ scope.row.participantA.name }}-{{ scope.row.participantAScore }}</el-tag>
                            vs
                            <el-tag>{{ scope.row.participantBScore }}-{{
                                scope.row.participantB ? scope.row.participantB.name : "" }}</el-tag>
                        </template>
                    </el-table-column>
                </el-table>
            </el-main>
        </el-col>
        <el-col :span="2">
        </el-col>
    </el-row>
</template>

<script>
import axios from 'axios';
import { ElMessage } from 'element-plus';

export default {
    name: 'ProgressManagement',
    data() {
        return {
            progresses: [],
            dialogFormVisible: false,
            currentProgress: {
                roundid: ""
            },
            matchAndRounds: [],
            defaultActive: "",
            openeds:[]
        };
    },
    created() {
        axios.post('/api/progressInfo/getMatchAndRounds')
            .then(response => {
                this.matchAndRounds = response.data;
                if(this.matchAndRounds.length>0){
                    this.openeds = [this.matchAndRounds[0].matchInfo.matchid];
                }
            })
            .catch(error => {
                this.showMessage('error', '获取赛事菜单列表失败:' + error)
            });
    },
    mounted(){
       
    },
    computed: {
        participantAName(row) {
            if (row && row.participantA) {
                return row.participantA.name;
            }
            return "";
        },
    },
    methods: {
        showProgressInfo(roundid) {
            axios.post('api/progressInfo/GetProgresses?roundid='+roundid)
                .then(response => {
                    this.progresses = response.data;
                })
                .catch(error => {
                    this.showMessage('error', "加载比赛信息失败:" + error);
                });
        },
        saveProgress() {
            this.modifyProgress();
        },
        modifyProgress() {
            axios.post('api/progressManagement/modifyProgress', this.currentProgress)
                .then(() => {
                    this.fetchProgresses();
                    this.dialogFormVisible = false;
                    this.currentProgress = {};
                    this.showMessage('info', "计分成功");
                })
                .catch(error => {
                    this.showMessage('error', "更新比赛信息失败:" + error);
                });
        },
        editProgress(index, row) {
            this.currentProgress = { ...row };
            this.dialogFormVisible = true;
        },
        cancelEdit() {
            this.dialogFormVisible = false;
        },
        handleOpen(index) {
            console.log('展开菜单', index);
        },
        handleClose(index) {
            console.log('关闭菜单', index);
        },
        showMessage(type, msg) {
            ElMessage({
                message: msg,
                type: type,
            })
        }
    }
}
</script>
<style scoped>
.el-table {
    background-color: blue;
}
</style>
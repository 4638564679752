<template>
    <div>
        <el-row style="height: auto;">
            <el-col :span="1"></el-col>
            <el-col :span="22">
                <div>
                    <el-form :inline="true">
                        <el-form-item label="">
                            <el-input v-model="keyword" placeholder="输入姓名、昵称或者身份证查询" />
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="fetchPlayers">查询</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <div>
                    <el-table :data="players">
                        <el-table-column label="照片">
                            <template #default="{ row }">
                                <img :src="row.avatarUrl" style="width: 50px; height: 50px; border-radius: 10%;" />
                            </template>
                        </el-table-column>
                        <el-table-column prop="name" label="姓名"></el-table-column>
                        <!-- <el-table-column prop="playerid" label="Playerid"></el-table-column>
                        <el-table-column prop="personId" label="PersonId"></el-table-column> -->
                        <el-table-column prop="gender" label="性别">
                            <template #default="scope">
                                <span>{{ scope.row.gender == 'F' ? '女' : '男' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="idNo" label="身份证号"></el-table-column>
                        <el-table-column prop="grade" label="等级"></el-table-column>
                        <el-table-column prop="approveState" label="审核情况">
                            <template #default="scope">
                                <span>{{ scope.row.approveState == '1' ? '通过' : scope.row.approveState ==
                                    '0'?'未通过':'未审核' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="contactinfo" label="联系信息"></el-table-column>
                        <el-table-column prop="introduction" label="简介"></el-table-column>
                        <el-table-column>
                            <!-- <template #header>
                                <el-button type="primary" @click="showAddPlayerDialog">添加球员</el-button>
                            </template> -->
                            <template #default="scope">
                                <el-button @click="editPlayer(scope.row)" type="primary" size="small">审核</el-button>
                                <el-popconfirm confirm-button-text="是" cancel-button-text="否" icon-color="#626AEF"
                                    title="删除将无法恢复数据，确认删除?" @confirm="deletePlayer(scope.$index, scope.row)">
                                    <template #reference>
                                        <el-button size="small" type="danger">删除</el-button>
                                    </template>
                                </el-popconfirm>
                                <el-button @click="editAvatar(scope.row)" size="small">照片</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-dialog v-model="addPlayerDialogVisible" title="添加/编辑球员">
                        <el-form :model="currentPlayer">
                            <el-form-item label="姓名">
                                <el-input v-model="currentPlayer.name"></el-input>
                            </el-form-item>
                            <el-form-item label="性别">
                                <el-select v-model="currentPlayer.gender" class="m-2" placeholder="请选择" size="large">
                                    <el-option v-for="item in genderDicList" :key="item.value" :label="item.name"
                                        :value="item.value" />
                                </el-select>
                            </el-form-item>
                            <el-form-item label="身份证号">
                                <el-input v-model="currentPlayer.idNo"></el-input>
                            </el-form-item>
                            <el-form-item label="联系信息">
                                <el-input v-model="currentPlayer.contactinfo"></el-input>
                            </el-form-item>
                            <el-form-item label="所属俱乐部">
                                <el-select v-model="currentPlayer.clubid" class="m-2" placeholder="请选择" size="large">
                                    <el-option v-for="item in clubs" :key="item.clubid" :label="item.name"
                                        :value="item.clubid" />
                                </el-select>
                            </el-form-item>
                            <el-form-item label="球员等级">
                                <el-select v-model="currentPlayer.grade" placeholder="请选择">
                                    <el-option label="A" value="A"></el-option>
                                    <el-option label="B" value="B"></el-option>
                                    <el-option label="C" value="C"></el-option>
                                    <el-option label="D" value="D"></el-option>
                                    <el-option label="E" value="E"></el-option>
                                    <el-option label="F" value="F"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="简介">
                                <el-input v-model="currentPlayer.introduction"></el-input>
                            </el-form-item>
                            <el-form-item label="审核">
                                <el-radio-group v-model="currentPlayer.approveState" class="ml-4">
                                    <el-radio value="1" size="large">通过</el-radio>
                                    <el-radio value="0" size="large">不通过</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-form>
                        <span class="dialog-footer">
                            <slot name="footer">
                                <el-button @click="cancelEditPlayer">取消</el-button>
                                <el-button type="primary" @click="savePlayer">确定</el-button>
                            </slot>

                        </span>
                    </el-dialog>

                    <el-dialog v-model="avatarDialogVisible" title="添加/编辑照片">
                        <el-form-item label="照片">
                            <el-upload class="avatar-uploader" action="" :show-file-list="false"
                                :http-request="upLoadAvatar" :on-success="handleAvatarSuccess"
                                :before-upload="beforeAvatarUpload">
                                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                                <el-icon v-else class="avatar-uploader-icon">
                                    <plus-icon />
                                </el-icon>
                            </el-upload>
                        </el-form-item>
                        <span class="dialog-footer">
                            <slot name="footer">
                                <el-button @click="avatarDialogVisible = false">关闭</el-button>
                            </slot>
                        </span>
                    </el-dialog>
                </div>
            </el-col>
            <el-col :span="1"></el-col>
        </el-row>
        <el-row>
            <el-col :span="3"></el-col>
            <el-col :span="18">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    v-model:currentPage="currentPage" :page-sizes="[10, 20, 30, 40]" v-model:page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </el-col>
            <el-col :span="3"></el-col>
        </el-row>
    </div>

</template>

<script>
import axios from 'axios';
import { ElMessage } from 'element-plus';
import { Plus } from '@element-plus/icons-vue';

export default {
    name: 'PlayerManagement',
    components: {
        // 注册 Plus 图标组件
        'plus-icon': Plus
    },
    data() {
        return {
            players: [],
            addPlayerDialogVisible: false,
            avatarDialogVisible: false,
            currentPlayer: {
                playerid: '',
            },
            clubs: [],
            genderDicList: [{ value: "M", name: "男" }, { value: "F", name: "女" }],
            imageUrl: "",
            currentPage: 1,
            pageSize: 10,
            total: 1000,
            keyword:''
        };
    },
    created() {
        axios.post('/api/clubInfo/getClubs')
            .then(response => {
                this.clubs = response.data;
            })
            .catch(error => {
                this.showMessage('error', '获取俱乐部信息失败:' + error)
            });
        this.fetchPlayers();
    },
    methods: {
        fetchPlayers() {
            axios.post('api/playerInfo/GetAllPlayersByPage', { currentPage: this.currentPage, pageSize: this.pageSize,keyword:this.keyword })
                .then(response => {
                    this.total = response.data.total;
                    this.players = response.data.content;
                })
                .catch(error => {
                    this.showMessage('error', '获取球员列表失败:' + error)
                });
        },
        showAddPlayerDialog() {
            this.addPlayerDialogVisible = true;
        },
        savePlayer() {
            if (this.currentPlayer.playerid) {
                this.modeifyPlayer();
            } else {
                this.addPlayer();
            }

        },
        modeifyPlayer() {
            axios.post('api/playerManagement/modifyPlayer', this.currentPlayer)
                .then(() => {
                    this.fetchPlayers();
                    this.addPlayerDialogVisible = false;
                    this.currentPlayer = {};
                })
                .catch(error => {
                    this.showMessage('error', '更新球员信息失败:' + error)
                });
        },
        addPlayer() {
            this.currentPlayer.playerid = "";
            axios.post('api/playerManagement/addPlayer', this.currentPlayer)
                .then(() => {
                    this.fetchPlayers();
                    this.addPlayerDialogVisible = false;
                    this.currentPlayer = {};
                })
                .catch(error => {
                    this.showMessage('error', '球员信息添加失败:' + error)
                });

        },
        editPlayer(player) {
            this.currentPlayer = { ...player };
            this.addPlayerDialogVisible = true;
        },
        cancelEditPlayer() {
            this.currentPlayer = {};
            this.addPlayerDialogVisible = false;
        },
        editAvatar(player) {
            this.currentPlayer = { ...player };
            this.avatarDialogVisible = true;
        },
        upLoadAvatar(param) {
            // 创建 FormData 对象
            const formData = new FormData();
            // 将文件添加到 FormData 对象中
            formData.append('file', param.file);
            formData.append('playerid', this.currentPlayer.playerid);
            axios.post('api/playerManagement/uploadAvatar', formData)
                .then(res => {
                    this.showMessage('success', '上传成功:')
                    // this.addPlayerDialogVisible = false;
                    this.handleAvatarSuccess(res, param.file);
                    return true;
                })
                .catch(error => {
                    this.showMessage('error', '上传失败:' + error)
                });
        },
        handleAvatarSuccess(response, uploadFile) {
            this.imageUrl = URL.createObjectURL(uploadFile);
        },
        beforeAvatarUpload(rawFile) {
            if (rawFile.type !== 'image/jpeg') {
                ElMessage.error('头像图片必须是JPG格式')
                return false
            } else if (rawFile.size / 1024 / 1024 > 2) {
                ElMessage.error('头像图片不能超过2MB!')
                return false
            }
            return true
        },
        handleSizeChange(val) {
            this.pageSize = val;
            // 请求后端数据，根据 pageSize 和 currentPage 获取数据
            this.fetchPlayers();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            // 请求后端数据，根据 pageSize 和 currentPage 获取数据
            this.fetchPlayers();
        },
        showMessage(type, msg) {
            ElMessage({
                message: msg,
                type: type,
            })
        }
    }
}

</script>


<style scoped>
.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>

<style>
.avatar-uploader .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
}
</style>
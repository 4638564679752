
import { createStore } from 'vuex';
 
export default createStore({
  state: {
    selectedMenuItemText: ''
  },
  mutations: {
    setSelectedMenuItemText(state, text) {
      state.selectedMenuItemText = text;
    }
  },
  actions: {
    updateSelectedMenuItemText({ commit }, text) {
      commit('setSelectedMenuItemText', text);
    }
  },
  getters: {
    getSelectedMenuItemText: state => state.selectedMenuItemText
  }
});
<template>
  <div>
    <!-- 根据用户信息渲染菜单 -->
    <el-menu mode="horizontal" :router="true" @select="selectMenu" class="menu">
      <el-menu-item v-for="item in menuItems" :key="item.sortNum" :index="item.menuLink" :data-text="item.menuName">{{ item.menuName
        }}</el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import axios from 'axios';
import { ElMessage } from 'element-plus';
import { mapActions } from 'vuex';

export default {
  name: 'MenuComponent',
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      menuItems: [],
    };
  },
  watch: {
    user(newUser) {
      this.fetchMenuItems(newUser);
    }
  },
  created() {
    this.fetchMenuItems(this.user);
  },
  methods: {
    fetchMenuItems(user) {
      if (user) {
        axios.post('api/PermissionManagement/GetMenusByUser', user)
          .then(response => {
            console.log(response);
            this.menuItems = response.data;
          })
          .catch(error => {
            this.showMessage('error', '获取用户菜单列表失败1:' + error)
          });
      } else {
        axios.post('api/PermissionManagement/getCommonMenus')
          .then(response => {
            this.menuItems = response.data;
          })
          .catch(error => {
            this.showMessage('error', '获取菜单列表失败2:' + error)
          });
      }
    },
    showMessage(type, msg) {
      ElMessage({
        message: msg,
        type: type,
      })
    },
    ...mapActions(['updateSelectedMenuItemText']),
    selectMenu(index){
      const selectedItem = this.menuItems.find(item => item.menuLink === index);
      if (selectedItem) {
        this.updateSelectedMenuItemText(selectedItem.menuName);
      }
    }
  }
};
</script>

<style scoped>
.menu {
  background-color: transparent;
}
</style>
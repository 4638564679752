<template>
    <div class="match-management">
        <el-table :data="matchs" @expand-change="handleExpandMatch" class="el-table">
            <el-table-column type="expand">
                <template #default="props">
                    <div m="4">
                        <p m="t-0 b-2">状态: {{ props.row.state == "0" ? "未发布" : "已发布" }}</p>
                        <p m="t-0 b-2">地点: {{ props.row.location }}</p>
                        <h3>赞助商</h3>
                        <el-table :data="props.row.sponsors">
                            <el-table-column label="名称" prop="sponsorName" />
                            <el-table-column label="赞助金额" prop="sponsorshipAmount" />
                            <el-table-column label="描述" prop="description" />
                            <el-table-column label="是否主办方" prop="isOrganizer=='1'?'是':'否'" />
                            <el-table-column align="right">
                                <template #header>
                                    <el-button type="primary" @click="dialogFormVisible1 = true">添加赞助</el-button>
                                </template>
                                <template #default="scope">
                                    <el-popconfirm confirm-button-text="是" cancel-button-text="否" icon-color="#626AEF"
                                        title="删除将无法恢复数据，确认删除?" @confirm="deletSponsor(scope.$index, scope.row)">
                                        <template #reference>
                                            <el-button size="small" type="danger">删除</el-button>
                                        </template>
                                    </el-popconfirm>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="标题" prop="title" />
            <el-table-column label="描述" prop="description" />
            <el-table-column label="地点" prop="location" />
            <el-table-column label="开始时间" prop="startTime" />
            <el-table-column label="结束时间" prop="endTime" />
            <el-table-column label="报名开始时间" prop="enrollBeginTime" />
            <el-table-column label="报名截止时间" prop="enrollEndTime" />
            <el-table-column label="比赛规则说明" prop="ruleInfo" />
            <el-table-column align="right">
                <template #header>
                    <el-button type="primary" @click="dialogFormVisible = true">添加比赛</el-button>
                    <!-- <el-input v-model="search" size="small" placeholder="Type to search" /> -->
                </template>
                <template #default="scope">
                    <el-button size="small" @click="editMatch(scope.$index, scope.row)">编辑</el-button>
                    <!-- <el-popconfirm confirm-button-text="是" cancel-button-text="否"
                        icon-color="#626AEF" title="删除将无法恢复数据，确认删除?" @confirm="deleteMatch(scope.$index, scope.row)">
                        <template #reference>
                            <el-button size="small" type="danger">删除</el-button>
                        </template>
    </el-popconfirm> -->
                    <el-button @click="publishMatch(scope.row)" type="primary" size="small">发布</el-button>
                    <el-button @click="automatchDiag()" type="primary" size="small">匹配</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog v-model="dialogFormVisible" title="添加/编辑比赛信息">
            <el-form :model="currentMatch">
                <el-form-item label="标题">
                    <el-input v-model="currentMatch.title"></el-input>
                </el-form-item>
                <el-form-item label="描述">
                    <el-input v-model="currentMatch.description"></el-input>
                </el-form-item>
                <el-form-item label="地点">
                    <el-input v-model="currentMatch.location"></el-input>
                </el-form-item>
                <el-form-item label="开始时间">
                    <el-date-picker v-model="currentMatch.startTime" type="datetime"
                        placeholder="选择日期和时间"></el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间">
                    <el-date-picker v-model="currentMatch.endTime" type="datetime"
                        placeholder="选择日期和时间"></el-date-picker>
                </el-form-item>
                <el-form-item label="报名开始时间">
                    <el-date-picker v-model="currentMatch.enrollBeginTime" type="datetime"
                        placeholder="选择日期和时间"></el-date-picker>
                </el-form-item>
                <el-form-item label="报名截止时间">
                    <el-date-picker v-model="currentMatch.enrollEndTime" type="datetime"
                        placeholder="选择日期和时间"></el-date-picker>
                </el-form-item>
                <el-form-item label="比赛规则说明">
                    <el-input v-model="currentMatch.ruleInfo"></el-input>
                </el-form-item>
                <el-form-item label="有效性">
                    <el-radio-group v-model="currentMatch.validFlag" class="ml-4">
                        <el-radio value="1" size="large">有效</el-radio>
                        <el-radio value="0" size="large">无效</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="cancelEdit">取消</el-button>
                    <el-button type="primary" @click="saveMatch">确定</el-button>
                </span>
            </template>
        </el-dialog>

        <el-dialog v-model="dialogFormVisible1" title="添加/编辑赞助商信息">
            <el-form :model="currentSponsor">
                <el-form-item label="商家">
                    <el-select v-model="currentSponsor.sponsorid" class="m-2" placeholder="Select" size="large">
                        <el-option v-for="item in clubs" :key="item.clubid" :label="item.name" :value="item.clubid" />
                    </el-select>
                </el-form-item>
                <el-form-item label="赞助金额">
                    <el-input-number v-model="currentSponsor.sponsorshipAmount" :precision="2" :step="0.0" />
                </el-form-item>
                <el-form-item label="描述">
                    <el-input v-model="currentSponsor.description"></el-input>
                </el-form-item>
                <el-form-item label="主办方">
                    <el-radio-group v-model="currentSponsor.isOrganizer" class="ml-4">
                        <el-radio value="1" size="large">是</el-radio>
                        <el-radio value="0" size="large">否</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="cancelEditSponsor">取消</el-button>
                    <el-button type="primary" @click="saveSponsor">确定</el-button>
                </span>
            </template>
        </el-dialog>

        <el-dialog v-model="dialogFormVisible2" title="匹配确认">
            <el-form>
                <el-form-item label="免比赛晋级人数">
                    <el-input-number v-model="byesNum" :precision="0" :step="0" />
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="cancelAutoMatch">取消</el-button>
                    <el-button type="primary" @click="startAutoMatch">确定</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import axios from 'axios';
import { ElMessage } from 'element-plus';

export default {
    name: 'MatchManagement',
    data() {
        return {
            matchs: [],
            selectedMatchRow: {},
            dialogFormVisible: false,
            dialogFormVisible1: false,
            dialogFormVisible2: false,
            currentMatch: {
                state: "0",
                validFlag: "1"
            },
            currentSponsor: {
                sponsorshipAmount: 0,
                isOrganizer: "0"
            },
            clubs: [],
            byesNum: 0
        };
    },
    created() {
        this.fetchMatchs();
        axios.post('/api/clubInfo/getClubs')
            .then(response => {
                this.clubs = response.data;
            })
            .catch(error => {
                this.showMessage('error', '获取商家信息列表失败:' + error)
            });
    },
    methods: {
        fetchMatchs() {
            axios.post('api/matchInfoManagement/getMatchInfos')
                .then(response => {
                    this.matchs = response.data;
                })
                .catch(error => {
                    this.showMessage('error', "加载比赛信息失败:" + error);
                });
        },
        saveMatch() {
            if (this.currentMatch.matchid) {
                this.modifyMatch();
            } else {
                this.addMatch();
            }
        },
        addMatch() {
            this.currentMatch.matchid = "";
            axios.post('api/matchInfoManagement/addMatchInfo', this.currentMatch)
                .then(() => {
                    this.fetchMatchs();
                    this.dialogFormVisible = false;
                    this.showMessage('info', "添加成功");
                })
                .catch(error => {
                    this.showMessage('error', "添加比赛信息失败:" + error);
                });
        },
        modifyMatch() {
            axios.post('api/matchInfoManagement/modifyMatchInfo', this.currentMatch)
                .then(() => {
                    this.fetchMatchs();
                    this.dialogFormVisible = false;
                    this.currentMatch = {};
                    this.showMessage('info', "更新成功");
                })
                .catch(error => {
                    this.showMessage('error', "更新比赛信息失败:" + error);
                });
        },
        editMatch(index, row) {
            this.currentMatch = { ...row };
            this.dialogFormVisible = true;
        },
        deleteMatch(index, row) {
            axios.post('api/matchInfoManagement/deleteMatchInfo?id=' + row.matchid)
                .then(() => {
                    this.fetchMatchs();
                    this.showMessage('success', "已删除！");
                })
                .catch(error => {
                    this.showMessage('error', "删除比赛信息失败:" + error);
                });
        },
        publishMatch(row) {
            this.currentMatch = { ...row };
            this.currentMatch.state = "1";
            this.modifyMatch();
        },
        cancelEdit() {
            this.dialogFormVisible = false;
        },
        handleExpandMatch(row) {
            this.selectedMatchRow = row;
        },
        saveSponsor() {
            this.currentSponsor.matchid = this.selectedMatchRow.matchid;
            axios.post('api/matchInfoManagement/addSponsor', this.currentSponsor)
                .then(() => {
                    this.fetchMatchs();
                    this.dialogFormVisible1 = false;
                    // if(this.selectedMatchRow.sponsors){
                    //     this.selectedMatchRow.sponsors.push(this.currentSponsor);
                    // }else{
                    //     this.selectedMatchRow.sponsors = [];
                    //     this.selectedMatchRow.sponsors.push(this.currentSponsor);
                    // }
                })
                .catch(error => {
                    this.showMessage('error', "添加赞助商信息失败:" + error);
                });
        },
        editSponsor(index, row) {
            this.currentSponsor = { ...row };
            this.dialogFormVisible1 = true;
        },
        deletSponsor(index, row) {
            axios.post('api/matchInfoManagement/deleteSponsor', row)
                .then(() => {
                    this.fetchMatchs();
                    this.showMessage('success', "已删除！");
                })
                .catch(error => {
                    this.showMessage('error', "删除赞助商信息失败:" + error);
                });
        },
        cancelEditSponsor() {
            this.dialogFormVisible1 = false;
        },
        viewMatchDetails(id) {
            this.$router.push({ name: 'MatchDetails', params: { id: id } });
        },
        automatchDiag() {
            this.dialogFormVisible2 = true;
        },
        cancelAutoMatch() {
            this.dialogFormVisible2 = false;
        },
        startAutoMatch() {
            axios.post('api/progressManagement/autoMatch?byesNum=' + this.byesNum)
                .then((res) => {
                    if (res.data.success) {
                        this.showMessage('success', "匹配成功！");
                    } else {
                        this.showMessage('error', res.data.errMsg);
                    }
                })
                .catch(error => {
                    this.showMessage('error', "匹配失败:" + error);
                });
        },
        showMessage(type, msg) {
            ElMessage({
                message: msg,
                type: type,
            })
        }
    }
}
</script>
<style scoped>
.el-table {
    background-color: blue;
}
</style>
<template>
    <div class="register-form">
        <el-form :model="registerForm" :rules="rules" ref="registerFormRef" label-width="80px">
            <el-form-item label="昵称" prop='nickName'>
                <el-input v-model="registerForm.nickName"></el-input>
            </el-form-item>
            <el-form-item label="姓名" prop='name'>
                <el-input v-model="registerForm.name"></el-input>
            </el-form-item>
            <el-form-item label="身份证号" prop='idNo'>
                <el-input v-model="registerForm.idNo"></el-input>
            </el-form-item>
            <el-form-item label="性别">
                <el-radio-group v-model="registerForm.gender">
                    <el-radio value="M">男</el-radio>
                    <el-radio value="F">女</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="省">
                <el-select v-model="registerForm.province" filterable remote clearable placeholder="输入搜索选择"
                    :remote-method="provinceSearch" :loading="provinceLoading" @change="provinceSelectChange">
                    <el-option v-for="item in searchedProvinces" :key="item.regionCode" :label="item.regionName"
                        :value="item.regionCode" />
                </el-select>
            </el-form-item>
            <el-form-item label="市">
                <el-select v-model="registerForm.city" filterable remote clearable placeholder="输入搜索选择"
                    :remote-method="citySearch" :loading="cityLoading" @change="citySelectChange">
                    <el-option v-for="item in searchedCities" :key="item.regionCode" :label="item.regionName"
                        :value="item.regionCode" />
                </el-select>
            </el-form-item>
            <el-form-item label="县">
                <el-select v-model="registerForm.county" filterable remote clearable placeholder="输入搜索选择"
                    :remote-method="countySearch" :loading="countyLoading">
                    <el-option v-for="item in searchedCounties" :key="item.regionCode" :label="item.regionName"
                        :value="item.regionCode" />
                </el-select>
            </el-form-item>
            <el-form-item label="手机号" prop="phone">
                <el-input v-model="registerForm.phone"></el-input>
            </el-form-item>
            <el-form-item label="联系信息">
                <el-input v-model="registerForm.contactinfo"></el-input>
            </el-form-item>
            <el-form-item label="球员等级">
                <el-select v-model="registerForm.grade" placeholder="请选择">
                    <el-option label="A" value="A"></el-option>
                    <el-option label="B" value="B"></el-option>
                    <el-option label="C" value="C"></el-option>
                    <el-option label="D" value="D"></el-option>
                    <el-option label="E" value="E"></el-option>
                    <el-option label="F" value="F"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="用户名" prop='username'>
                <el-input v-model="registerForm.username"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
                <el-input type="password" v-model="registerForm.password" show-password></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="code">
                <div class="captcha-container">
                    <el-input v-model="registerForm.code" placeholder="请输入验证码"></el-input>
                    <el-image :src="captchaImageUrl" alt="验证码" @click="refreshCaptcha" class="captcha-image" />
                    <el-button @click="refreshCaptcha">刷新</el-button>
                </div>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handleSubmit">注册</el-button>
                <el-button @click="cancel">取消</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { ElForm, ElFormItem, ElInput, ElButton, ElMessage, ElMessageBox } from 'element-plus';
import axios from 'axios';

export default {
    components: {
        ElForm,
        ElFormItem,
        ElInput,
        ElButton
    },
    data() {
        return {
            registerForm: {
                playerid: '',
                nickName: '',
                name: '',
                idNo: '',
                gender: 'M',
                province: '',
                city: '',
                county: '',
                phone: '',
                contactinfo: '',
                grade: ''
            },
            rules: {
                nickName: [{ required: true, message: '请输入昵称', trigger: 'blur' }],
                name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
                idNo: [{ required: true, message: '请输入身份证号', trigger: 'blur' }],
                phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
                code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
                username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
                password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
            },
            captchaImageUrl: '',
            captchaId: '',
            provinces: [],
            searchedProvinces: [],
            provinceLoading: false,
            cities: [],
            searchedCities: [],
            cityLoading: false,
            counties: [],
            searchedCounties: [],
            countyLoading: false
        };
    },
    watch: {
        captchaId(newval) {
            if (newval !== '') {
                this.getCaptcha();
            }

        }
    },
    mounted() {
        if (this.captchaId.length == 0) {
            this.getCaptchaId();
        }
        this.refreshCaptcha();
        this.fetchProvince();
    },
    methods: {
        getCaptchaId() {
            axios.get('api/auth/getCaptchaId')
                .then((response) => {
                    this.captchaId = response.data;
                })
                .catch(error => {
                    ElMessage.error('获取验证码id失败:' + error);
                });
        },
        async getCaptcha() {
            try {
                if(this.captchaId && this.captchaId.length>0){
                    const response = await axios.get('api/auth/getCaptcha?id=' + this.captchaId, { responseType: 'blob' });
                    const blob = response.data;
                    this.captchaImageUrl = URL.createObjectURL(blob);
                }
            } catch (error) {
                ElMessage.error('获取验证码失败');
            }
        },
        refreshCaptcha() {
            this.getCaptcha();
        },
        fetchProvince(){
            axios.get('api/baseData/getProvinces')
            .then((response) => {
                this.provinces = response.data;
            })
            .catch(error => {
                ElMessage.error('获取省份失败:' + error);
            });
        },
        provinceSearch(query) {
            if (query) {
                this.provinceLoading = true
                setTimeout(() => {
                    this.provinceLoading = false;
                    this.searchedProvinces = this.provinces.filter((item) => {
                        return item.regionName.toLowerCase().includes(query.toLowerCase()) || item.pyCode.toLowerCase().includes(query.toLowerCase())
                    })
                }, 200)
            } else {
                this.searchedProvinces = []
            }
        },
        provinceSelectChange(regionCode) {
            axios.get('api/baseData/getReginByParent?parentCode=' + regionCode)
                .then((response) => {
                    this.cities = response.data;
                    this.searchedcities = response.data;
                })
                .catch(error => {
                    ElMessage.error('获取市失败:' + error);
                });
        },
        citySearch(query) {
            if (query) {
                this.cityLoading = true
                setTimeout(() => {
                    this.cityLoading = false;
                    this.searchedCities = this.cities.filter((item) => {
                        return item.regionName.toLowerCase().includes(query.toLowerCase()) || item.pyCode.toLowerCase().includes(query.toLowerCase())
                    })
                }, 200)
            } else {
                this.searchedCities = []
            }
        },
        citySelectChange(regionCode) {
            axios.get('api/baseData/getReginByParent?parentCode=' + regionCode)
                .then((response) => {
                    this.counties = response.data;
                    this.searchedCounties = response.data;
                })
                .catch(error => {
                    ElMessage.error('获取县失败:' + error);
                });
        },
        countySearch(query) {
            if (query) {
                this.provinceLoading = true
                setTimeout(() => {
                    this.countyLoading = false;
                    this.searchedCounties = this.counties.filter((item) => {
                        return item.regionName.toLowerCase().includes(query.toLowerCase()) || item.pyCode.toLowerCase().includes(query.toLowerCase())
                    })
                }, 200)
            } else {
                this.searchedCounties = []
            }
        },
        async handleSubmit() {
            this.$refs.registerFormRef.validate(async (valid) => {
                if (valid) {
                    try {
                        const validateRes = await axios.post('api/auth/validateCaptcha?id=' + this.captchaId + '&code=' + this.registerForm.code);
                        if (validateRes.data) {
                            const response = await axios.post('api/auth/register', this.registerForm);
                            const result = response.data;
                            if (result.success) {
                                ElMessageBox.alert('注册成功,登录后可以继续完善信息', '提示', {
                                    confirmButtonText: '确定',
                                    type: 'info'
                                }).then(() => {
                                    this.$router.push('/');
                                }).catch(() => {
                                    this.$router.push('/');
                                });
                            } else {
                                ElMessage.error('注册失败:' + result.errMsg);
                            }
                        } else {
                            ElMessage.error('验证码验证失败');
                        }

                    } catch (error) {
                        ElMessage.error('提交注册信息失败:' + error);
                    }
                }
            });
        },
        cancel() {
            this.$router.push('/');
        }
    }
};
</script>

<style>
.register-form {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
}

.captcha-container {
    display: flex;
    align-items: center;
}

.captcha-image {
    margin-left: 10px;
    cursor: pointer;
}
</style>
import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../components/HomePage.vue';
import BilliardClubManagement from '../components/Management/BilliardClubManagement.vue';
import ParticipantManagement from '../components/Management/ParticipantManagement.vue';
import MatchManagement from '../components/Management/MatchManagement.vue';
import PlayerManagement from '@/components/Management/PlayerManagement.vue';
import RefereeManagement from '@/components/Management/RefereeManagement.vue';
import ClubInfos from '@/components/ShowInfo/ClubInfos.vue'
import ClubInfo from '@/components/ShowInfo/ClubInfo.vue'
import PlayerInfo from '@/components/ShowInfo/PlayerInfo.vue';
import RefereeInfos from '@/components/ShowInfo/RefereeInfos.vue';
import ProgressManagement from '@/components/Management/ProgressesManagement.vue';
import ProgressInfo from '@/components/ShowInfo/ProgressesInfo.vue';
import ProgressesFlow from '@/components/ShowInfo/ProgressesFlow.vue';
import PlayerRegister from '@/components/PlayerRegister.vue';
import PersonelInfo from '@/components/PersonelInfo.vue';
import PlayerInfos from '@/components/ShowInfo/PlayerInfos.vue';

const routes = [
    { path: '/', name: 'HomePage', component: HomePage },
    { path: '/clubManagement', name: 'BilliardClubManagement', component: BilliardClubManagement },
    { path: '/participantManagement', name: 'ParticipantManagement', component: ParticipantManagement },
    { path: '/matchManagement', name: 'MatchManagement', component: MatchManagement },
    { path: '/playerManagement', name: 'PlayerManagement', component: PlayerManagement },
    { path: '/pefereeManagement', name: 'RefereeManagement', component: RefereeManagement },
    { path: '/playerInfo', name: 'PlayerInfo', component: PlayerInfo },
    { path: '/clubInfos', name: 'ClubInfos', component: ClubInfos },
    { path: '/ClubInfo/:id', name: 'ClubInfo', component: ClubInfo },
    { path: '/refereeInfos', name: 'RefereeInfos', component: RefereeInfos },
    { path: '/progressManagement', name: 'ProgressManagement', component: ProgressManagement },
    { path: '/progressInfo', name: 'ProgressInfo', component: ProgressInfo },
    { path: '/progressesFlow', name: 'ProgressesFlow', component: ProgressesFlow },
    { path: '/playerRegister', name: 'PlayerRegister', component: PlayerRegister },
    { path: '/personelInfo', name: 'PersonelInfo', component: PersonelInfo },
    { path: '/playerInfos', name: 'PlayerInfos', component: PlayerInfos },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;

<template>
    <el-row>
        <el-col :span='2'></el-col>
        <el-col :span='20'>
            <div>
                <el-table :data="referees">
                    <el-table-column label="照片">
                        <template #default="{ row }">
                            <img :src="row.personInfo.avatarUrl"
                                style="width: 50px; height: 50px; border-radius: 10%;" />
                        </template>
                    </el-table-column>
                    <el-table-column prop="personInfo.name" label="姓名"></el-table-column>
                    <el-table-column prop="personInfo.gender" label="性别">
                        <template #default="scope">
                            <span>{{ scope.row.personInfo.gender == 'F' ? '女' : '男' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="personInfo.idNo" label="身份证号"></el-table-column>
                    <el-table-column prop="personInfo.contactinfo" label="联系信息"></el-table-column>
                    <el-table-column prop="refereeInfo.isMain" label="主裁判">
                        <template #default="scope">
                            <span>{{ scope.row.refereeInfo.isMain == '1' ? '是' : '否' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="refereeInfo.introduction" label="简介"></el-table-column>
                    <el-table-column>
                        <template #header>
                            <el-button type="primary" @click="showAddRefereeDialog">添加裁判</el-button>
                        </template>
                        <template #default="scope">
                            <el-button @click="editReferee(scope.row)" size="small">编辑</el-button>
                            <el-popconfirm confirm-button-text="是" cancel-button-text="否" icon-color="#626AEF"
                                title="删除将无法恢复数据，确认删除?" @confirm="deleteReferee(scope.$index, scope.row)">
                                <template #reference>
                                    <el-button size="small" type="danger">删除</el-button>
                                </template>
                            </el-popconfirm>
                            <el-button @click="editAvatar(scope.row)" size="small">照片</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-dialog v-model="addRefereeDialogVisible" title="添加/编辑裁判">
                    <el-form :model="currentReferee">
                        <el-form-item label="昵称">
                            <el-input v-model="currentReferee.personInfo.nickName"></el-input>
                        </el-form-item>
                        <el-form-item label="姓名">
                            <el-input v-model="currentReferee.personInfo.name"></el-input>
                        </el-form-item>
                        <el-form-item label="性别">
                            <el-select v-model="currentReferee.personInfo.gender" class="m-2" placeholder="请选择"
                                size="large">
                                <el-option v-for="item in genderDicList" :key="item.value" :label="item.name"
                                    :value="item.value" />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="身份证号">
                            <el-input v-model="currentReferee.personInfo.idNo"></el-input>
                        </el-form-item>
                        <el-form-item label="联系信息">
                            <el-input v-model="currentReferee.personInfo.contactinfo"></el-input>
                        </el-form-item>
                        <el-form-item label="所属俱乐部">
                            <el-select v-model="currentReferee.refereeInfo.clubid" class="m-2" placeholder="请选择"
                                size="large">
                                <el-option v-for="item in clubs" :key="item.clubid" :label="item.name"
                                    :value="item.clubid" />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="主裁判">
                            <el-radio-group v-model="currentReferee.refereeInfo.isMain" class="ml-4">
                                <el-radio label="1">是</el-radio>
                                <el-radio label="0">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="简介">
                            <el-input v-model="currentReferee.refereeInfo.introduction"></el-input>
                        </el-form-item>
                    </el-form>
                    <span class="dialog-footer">
                        <slot name="footer">
                            <el-button @click="cancelEditReferee">取消</el-button>
                            <el-button type="primary" @click="saveReferee">确定</el-button>
                        </slot>

                    </span>
                </el-dialog>

                <el-dialog v-model="avatarDialogVisible" title="添加/编辑照片">
                    <el-form-item label="照片">
                        <el-upload class="avatar-uploader" action="" :show-file-list="false"
                            :http-request="upLoadAvatar" :on-success="handleAvatarSuccess"
                            :before-upload="beforeAvatarUpload">
                            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                            <el-icon v-else class="avatar-uploader-icon">
                                <plus-icon />
                            </el-icon>
                        </el-upload>
                    </el-form-item>
                    <span class="dialog-footer">
                        <slot name="footer">
                            <el-button @click="avatarDialogVisible = false">关闭</el-button>
                        </slot>
                    </span>
                </el-dialog>
            </div>
        </el-col>
        <el-col :span='2'></el-col>
    </el-row>

</template>

<script>
import axios from 'axios';
import { ElMessage } from 'element-plus';
import { Plus } from '@element-plus/icons-vue';

export default {
    name: 'RefereeManagement',
    components: {
        // 注册 Plus 图标组件
        'plus-icon': Plus
    },
    data() {
        return {
            referees: [],
            addRefereeDialogVisible: false,
            avatarDialogVisible: false,
            currentReferee: {
                refereeInfo: {
                    personId: ''
                },
                personInfo: {
                    personId: ''
                }
            },
            clubs: [],
            genderDicList: [{ value: "M", name: "男" }, { value: "F", name: "女" }],
            imageUrl: ""
        };
    },
    created() {
        axios.post('/api/clubInfo/getClubs')
            .then(response => {
                this.clubs = response.data;
            })
            .catch(error => {
                this.showMessage('error', '获取俱乐部信息失败:' + error)
            });
        this.fetchReferees();
    },
    methods: {
        fetchReferees() {
            axios.post('api/refereeInfo/GetReferees')
                .then(response => {
                    this.referees = response.data;
                })
                .catch(error => {
                    this.showMessage('error', '获取球员列表失败:' + error)
                });
        },
        showAddRefereeDialog() {
            this.addRefereeDialogVisible = true;
        },
        saveReferee() {
            if (this.currentReferee.refereeInfo.refereeid) {
                this.modeifyReferee();
            } else {
                this.addReferee();
            }

        },
        modeifyReferee() {
            axios.post('api/refereeManagement/modifyReferee', this.currentReferee)
                .then(() => {
                    this.fetchReferees();
                    this.addRefereeDialogVisible = false;
                    this.reInitCurrentReferee();
                })
                .catch(error => {
                    this.showMessage('error', '更新裁判信息失败:' + error)
                });
        },
        addReferee() {
            this.currentReferee.refereeInfo.refereeid = "";
            axios.post('api/refereeManagement/addReferee', this.currentReferee)
                .then(() => {
                    this.fetchReferees();
                    this.addRefereeDialogVisible = false;
                    this.reInitCurrentReferee();
                })
                .catch(error => {
                    this.showMessage('error', '裁判信息添加失败:' + error)
                });

        },
        reInitCurrentReferee() {
            this.currentReferee = {
                refereeInfo: {
                    personId: ''
                },
                personInfo: {
                    personId: ''
                }
            };
        },
        editReferee(referee) {
            this.currentReferee = { ...referee };
            this.addRefereeDialogVisible = true;
        },
        cancelEditReferee() {
            this.reInitCurrentReferee();
            this.addRefereeDialogVisible = false;
        },
        editAvatar(referee) {
            this.currentReferee = { ...referee };
            this.avatarDialogVisible = true;
        },
        deleteReferee(index, row) {
            axios.post('api/refereeManagement/deleteReferee', row.refereeInfo)
                .then(() => {
                    this.fetchReferees();
                    this.reInitCurrentReferee();
                    this.showMessage('success', '删除成功')
                })
                .catch(error => {
                    this.showMessage('error', '裁判信息删除失败:' + error)
                });
        },
        upLoadAvatar(param) {
            // 创建 FormData 对象
            const formData = new FormData();
            // 将文件添加到 FormData 对象中
            formData.append('file', param.file);
            formData.append('refereeid', this.currentReferee.refereeInfo.refereeid);
            axios.post('api/refereeManagement/uploadAvatar', formData)
                .then(res => {
                    this.showMessage('success', '上传成功:')
                    // this.addRefereeDialogVisible = false;
                    this.handleAvatarSuccess(res, param.file);
                    return true;
                })
                .catch(error => {
                    this.showMessage('error', '上传失败:' + error)
                });
        },
        handleAvatarSuccess(response, uploadFile) {
            this.imageUrl = URL.createObjectURL(uploadFile);
        },
        beforeAvatarUpload(rawFile) {
            if (rawFile.type !== 'image/jpeg') {
                ElMessage.error('头像图片必须是JPG格式')
                return false
            } else if (rawFile.size / 1024 / 1024 > 2) {
                ElMessage.error('头像图片不能超过2MB!')
                return false
            }
            return true
        },
        showMessage(type, msg) {
            ElMessage({
                message: msg,
                type: type,
            })
        }
    }
}

</script>


<style scoped>
.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>

<style>
.avatar-uploader .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
}

.content {
    width: 80%;
    /* 设置宽度为父元素的80% */
    margin: auto;
    /* 水平居中对齐 */
    background-color: blueviolet;
}
</style>
<template>
  <div id="echart2"></div>
</template>


<script>
import * as echarts from 'echarts';

export default {
  data() {
    return {
      flowIndex: 1,
    }
  },
  created() {
    this.flow(1)
  },
  mounted() {
    this.$nextTick(() => {
      this.setOption()
    })
  },
  computed: {},
  methods: {
    flow(index) {
      this.flowIndex = index;
      this.$nextTick(() => {
        if (index == 1) {
          this.getEchart2();
        }
      })
    },
    getEchart2() {
      echarts.init(document.getElementById('echart2')).dispose();
      var chartDom = document.getElementById('echart2');
      this.myChart = echarts.init(chartDom);
      var charts = {
        nodes: [ // 节点
          {
            name: '开始', value: [50, 600],//坐标
            label: {
              borderWidth: 1, // 边框宽度
              borderRadius: [4, 4, 4, 4],
              backgroundColor: '#7856FF',
              color: '#fff',
              padding: [10, 8, 10, 8],
            }
          },
          {
            name: '出发', value: [150, 500],
            label: {
              borderColor: '#387DFF', // 边框颜色
              borderWidth: 1, // 边框宽度
              borderRadius: [4, 4, 4, 4],
              backgroundColor: '#fff',
              color: '#387DFF',
              padding: [8, 5, 7, 5],
            },
          },
          {
            name: '加油', value: [200, 800],
            label: {
              borderColor: '#387DFF', // 边框颜色
              borderWidth: 1, // 边框宽度
              borderRadius: [4, 4, 4, 4],
              backgroundColor: '#fff',
              color: '#387DFF',
              padding: [8, 5, 7, 5],
            },
          },
          {
            name: '站点1', value: [300, 800],
            label: {
              borderColor: '#387DFF', // 边框颜色
              borderWidth: 1, // 边框宽度
              borderRadius: [4, 4, 4, 4],
              backgroundColor: '#fff',
              color: '#387DFF',
              padding: [8, 5, 7, 5],
            },
          },
          {
            name: '站点2', value: [450, 800],
            label: {
              borderColor: '#387DFF', // 边框颜色
              borderWidth: 1, // 边框宽度
              borderRadius: [4, 4, 4, 4],
              backgroundColor: '#fff',
              color: '#387DFF',
              padding: [8, 5, 7, 5],
            },
          },
          {
            name: '站点3', value: [600, 800],
            label: {
              borderColor: '#387DFF', // 边框颜色
              borderWidth: 1, // 边框宽度
              borderRadius: [4, 4, 4, 4],
              backgroundColor: '#fff',
              color: '#387DFF',
              padding: [8, 5, 7, 5],
            },
          },
          {
            name: '站点4', value: [800, 800],
            label: {
              borderColor: '#387DFF', // 边框颜色
              borderWidth: 1, // 边框宽度
              borderRadius: [4, 4, 4, 4],
              backgroundColor: '#fff',
              color: '#387DFF',
              padding: [8, 5, 7, 5],
            },
          },
          {
            name: '加油2', value: [200, 200],
            label: {
              borderColor: '#387DFF', // 边框颜色
              borderWidth: 1, // 边框宽度
              borderRadius: [4, 4, 4, 4],
              backgroundColor: '#fff',
              color: '#387DFF',
              padding: [8, 5, 7, 5],
            },
          },
          {
            name: '预备站点1', value: [300, 200],
            label: {
              borderColor: '#387DFF', // 边框颜色
              borderWidth: 1, // 边框宽度
              borderRadius: [4, 4, 4, 4],
              backgroundColor: '#fff',
              color: '#387DFF',
              padding: [8, 5, 7, 5],
            },
          },
          {
            name: '预备站点2', value: [450, 200],
            label: {
              borderColor: '#387DFF', // 边框颜色
              borderWidth: 1, // 边框宽度
              borderRadius: [4, 4, 4, 4],
              backgroundColor: '#fff',
              color: '#387DFF',
              padding: [8, 5, 7, 5],
            },
          },
          {
            name: '出口', value: [800, 500],
            label: {
              borderColor: '#387DFF', // 边框颜色
              borderWidth: 1, // 边框宽度
              borderRadius: [4, 4, 4, 4],
              backgroundColor: '#fff',
              color: '#387DFF',
              padding: [8, 5, 7, 5],
            },
          },
          {
            name: '终点', value: [1000, 500],
            label: {
              backgroundColor: '#13C2C2',
              color: '#fff',
              borderRadius: [4, 4, 4, 4],
              padding: [10, 8, 10, 8],
            },
          },
          {
            name: '冠军', value: [500, 1000],
            label: {
              borderColor: '#387DFF', // 边框颜色
              borderWidth: 1, // 边框宽度
              borderRadius: [4, 4, 4, 4],
              backgroundColor: '#fff',
              color: '#387DFF',
              padding: [8, 5, 7, 5],
            },
          },
          {
            name: 'G', value: [400, 900],
            label: {
              borderColor: '#387DFF', // 边框颜色
              borderWidth: 1, // 边框宽度
              borderRadius: [4, 4, 4, 4],
              backgroundColor: '#fff',
              color: '#387DFF',
              padding: [8, 5, 7, 5],
            },
          },
          {
            name: 'H', value: [600, 900],
            label: {
              borderColor: '#387DFF', // 边框颜色
              borderWidth: 1, // 边框宽度
              borderRadius: [4, 4, 4, 4],
              backgroundColor: '#fff',
              color: '#387DFF',
              padding: [8, 5, 7, 5],
            },
          },

        ],
        linesData: [ // 连线
          { name: '', coords: [[50, 600], [50, 500]], symbol: 'none' },//起点和终点坐标
          { name: '', coords: [[50, 500], [980, 500]] },
          { name: '', coords: [[150, 500], [150, 200]], symbol: 'none' },
          { name: '', coords: [[150, 500], [150, 800]], symbol: 'none' },
          { name: '', coords: [[150, 800], [800, 800]], symbol: 'none' },
          { name: '', coords: [[805, 800], [805, 500]], symbol: 'none' },
          { name: '', coords: [[150, 200], [805, 200]], symbol: 'none' },
          { name: '', coords: [[805, 200], [805, 500]], symbol: 'none' },
        ]
      }
      var option = {//整个图表的配置，包括坐标轴、网格、提示框等
        xAxis: {
          min: 0,
          max: 1060,
          show: false,
          type: "value",
        },
        yAxis: {
          min: 0,
          max: 1060,
          show: false,
          type: "value",
        },
        grid: {
          left: '1%',
          right: '0',
          bottom: 0,
          top: 0
        },
        tooltip: {
          show: false,
          axisPointer: {
            type: "shadow",
          },
          borderColor: "white",
          backgroundColor: "white",
          borderWidth: 1,
          padding: 0,
          textStyle: {
            fontSize: 14,
            color: '#333',
          },
        },
        series: [
          {
            type: "graph",
            coordinateSystem: "cartesian2d",
            symbol: "rect",
            symbolSize: [0.01, 0.01],
            // edgeSymbol: ['', 'arrow'],
            // edgeSymbolSize: [0, 0],
            lineStyle: {
              width: 0,
              color: '#387DFF'
            },
            itemStyle: {
              color: "rgb(194, 194, 194)",
            },
            symbolOffset: [10, 0],
            force: {
              edgeLength: 10,//连线的长度
              repulsion: 50 //子节点之间的间距
            },
            label: {
              show: true,
              color: '#387DFF', // 节点文字颜色
            },
            effect: {
              show: true, //是否 运动
              trailLength: 0,
              symbol: 'triangle',
              symbolSize: 15,
              period: 2
            },
            data: charts.nodes
          },

          {
            type: "lines",
            polyline: false,
            coordinateSystem: "cartesian2d",
            lineStyle: {
              // type: "dashed", // 线条类型
              opacity: 1,
              width: 2,
              color: '#fcd75f',
            },
            effect: {
              show: true,
              trailLength: 0,// 特效存在时间长度
              symbol: 'arrow', // 特效的形状
              image: '../../../public/jd/button.jpg',
              symbolSize: 15, // 特效大小
              // loop: true, // 特效循环
              // scale: 1.5, // 特效缩放
              period: 1, // 特效速度
              // random: true, // 特效随机
              color: '#f30b03', // 特效颜色
              // brushType: 'both', // 特效覆盖类型
            },
            // animation: true, // 开启动画
            // animationDuration: 3000, // 动画时长
            symbol: ['circle', 'arrow'],
            symbolSize: 10,
            label: {
              show: true,
              position: "middle",
              fontSize: 16,
              color: '#08979C',
              formatter: function (args) {
                let val = args.data.name;
                var strs = val.split("");
                var str = "";
                if (args.data.linkView) {
                  for (var i = 0, s; (s = strs[i++]);) {
                    str += s;
                    if (!(i % 15)) str += "\n";
                  }
                  return str;
                }
              },

            },

            // lineStyle: {

            //     color: '#65B7E3',

            //     width: 2

            // },

            data: charts.linesData,

          },

        ],

      };
      option && this.myChart.setOption(option);
      let chart = this.myChart
      window.addEventListener('resize', function () {//监听窗口大小变化，自动调整图表大小
        chart.resize();
      });
    },
  },
}
</script>
<style scoped>
#echart2 {
  width: 100%;
  height: 59rem;
  margin: 0 auto auto;
  margin-left: 2rem;
}
</style>

<template>
  <div id="homePageDiv">
    <el-row :gutter="20">
    <el-col :span="5">

    </el-col>
    <el-col :span="10">
      <el-carousel :interval="4000" type="card" height="300px">
        <el-carousel-item v-for="item in 6" :key="item">
          <el-image src='https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg' />
        </el-carousel-item>
      </el-carousel>
    </el-col>
    <el-col :span="6">
      <div class="qrcode-container">
        <p>扫描关注公众号</p>
        <img src="@/assets/qrcode.png" alt="公众号二维码" />
      </div>
    </el-col>
    <el-col :span="3">

    </el-col>
  </el-row>
  <el-row :gutter="20">
    <el-col :span="3">
    </el-col>
    <el-col :span="18" stripe  class="">
    <el-divider content-position="center">赛事信息</el-divider>
      <el-table :data="matchs">
      <el-table-column label="标题" prop="title" />
      <el-table-column label="描述" prop="description" />
      <el-table-column label="地点" prop="location" />
      <el-table-column label="开始时间" prop="startTime" />
      <el-table-column label="结束时间" prop="endTime" />
      <el-table-column label="报名开始时间" prop="enrollBeginTime" />
      <el-table-column label="报名截止时间" prop="enrollEndTime" />
      <el-table-column label="比赛规则说明" prop="ruleInfo" />
      </el-table>
    </el-col>
    <el-col :span="3">
    </el-col>
  </el-row>
  </div>
</template>

<script>
import axios from 'axios';
import { ElMessage } from 'element-plus';

export default {
  name: 'MatchManagement',
  data() {
    return {
      matchs: [],
    };
  },
  created() {
    this.fetchMatchs();
  },
  methods: {
    fetchMatchs() {
      axios.post('api/matchInfoManagement/getMatchInfos')
        .then(response => {
          this.matchs = response.data;
        })
        .catch(error => {
          ElMessage.error('加载比赛信息失败' + error);
        });
    }
  }
}
</script>
<style>
#homePageDiv .el-row {
  margin-bottom: 50px;
  margin-top: 20px;
  height: 30vh;
}
#homePageDiv .el-table {
    background-color: transparent !important; /* 设置背景色为透明 */
}

#homePageDiv .el-table .el-table__row {
    background-color: transparent !important;
}

#homePageDiv .el-table .el-table__body-wrapper .cell {
    background-color: transparent !important;
    color: #ffffff !important; /* 设置字体颜色为亮色，这里假设为白色 */
}

#homePageDiv .el-table .el-table__header {
    background-color: transparent !important; /* 设置列头背景色为透明 */
    color: #ffffff !important; /* 设置字体颜色为亮色，这里假设为白色 */
}

#homePageDiv .el-table__row:hover {
    background: rgba(14, 95, 255, 0.1) !important;
}


</style>
<style scoped>

.qrcode-container {
  text-align: center;
  padding: 20px;
  /* background-color: #f0f0f0; */
}

.qrcode-container img {
  max-width: 100%;
  height: 180px;
}
</style>
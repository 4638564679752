<template>
  <el-row>
    <el-col :span="2"></el-col>
    <el-col :span="20">
      <div>
        <el-table :data="clubs" :row-style="rowStyle">
          <el-table-column label="图标">
            <template #default="{ row }">
              <img :src="row.logoUrl" style="width: 50px; height: 50px; border-radius: 10%;" />
            </template>
          </el-table-column>
          <el-table-column prop="name" label="名称" />
          <el-table-column prop="address" label="地址" />
          <el-table-column prop="contactinfo" label="联系信息" />
          <el-table-column prop="introduction" label="简介" />
          <el-table-column>
            <template #header>
              <el-button type="primary" @click="addClub">添加俱乐部</el-button>
              <!-- <el-input v-model="search" size="small" placeholder="Type to search" /> -->
            </template>
            <template #default="scope">
              <el-button size="small" @click="editClub(scope.row)">编辑</el-button>
              <el-popconfirm confirm-button-text="是" cancel-button-text="否" icon-color="#626AEF" title="删除将无法恢复数据，确认删除?"
                @confirm="deleteClub(scope.row)">
                <template #reference>
                  <el-button size="small" type="danger">删除</el-button>
                </template>
              </el-popconfirm>
              <el-button @click="editLogo(scope.row)" size="small">照片</el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-dialog title="添加/编辑俱乐部" v-model="showDialog">
          <el-form :model="currentClub">
            <el-form-item label="名称">
              <el-input v-model="currentClub.name"></el-input>
            </el-form-item>
            <el-form-item label="省">
              <el-select v-model="currentClub.province" filterable remote clearable placeholder="输入搜索选择"
                :remote-method="provinceSearch" :loading="provinceLoading" @change="provinceSelectChange">
                <el-option v-for="item in searchedProvinces" :key="item.regionCode" :label="item.regionName"
                  :value="item.regionCode" />
              </el-select>
            </el-form-item>
            <el-form-item label="市">
              <el-select v-model="currentClub.city" filterable remote clearable placeholder="输入搜索选择"
                :remote-method="citySearch" :loading="cityLoading" @change="citySelectChange">
                <el-option v-for="item in searchedCities" :key="item.regionCode" :label="item.regionName"
                  :value="item.regionCode" />
              </el-select>
            </el-form-item>
            <el-form-item label="县">
              <el-select v-model="currentClub.county" filterable remote clearable placeholder="输入搜索选择"
                :remote-method="countySearch" :loading="countyLoading">
                <el-option v-for="item in searchedCounties" :key="item.regionCode" :label="item.regionName"
                  :value="item.regionCode" />
              </el-select>
            </el-form-item>
            <el-form-item label="详细地址">
              <el-input v-model="currentClub.address"></el-input>
            </el-form-item>
            <el-form-item label="联系信息">
              <el-input v-model="currentClub.contactinfo"></el-input>
            </el-form-item>
            <el-form-item label="简介">
              <el-input v-model="currentClub.introduction"></el-input>
            </el-form-item>
            <el-form-item label="有效性">
              <el-radio-group v-model="currentClub.validFlag" class="ml-4">
                <el-radio value="1" size="large">有效</el-radio>
                <el-radio value="0" size="large">无效</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="showDialog = false">取消</el-button>
              <el-button type="primary" @click="saveClub">保存</el-button>
            </span>
          </template>
        </el-dialog>

        <el-dialog v-model="logoDialogVisible" title="添加/编辑照片">
          <el-form-item label="照片">
            <el-upload class="logo-uploader" action="" :show-file-list="false" :http-request="upLoadLogo"
              :on-success="handleLogoSuccess" :before-upload="beforeLogoUpload">
              <img v-if="imageUrl" :src="imageUrl" class="logo" />
              <el-icon v-else class="logo-uploader-icon">
                <Plus />
              </el-icon>
            </el-upload>
          </el-form-item>
          <span class="dialog-footer">
            <slot name="footer">
              <el-button @click="logoDialogVisible = false">关闭</el-button>
            </slot>
          </span>
        </el-dialog>
      </div>
    </el-col>
    <el-col :span="2"></el-col>
  </el-row>

</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { ElMessage } from 'element-plus';
import { Plus } from '@element-plus/icons-vue';

export default {
  name: 'ClubManagement',
  components: {
    Plus
  },
  setup() {
    const clubs = ref([]);
    const showDialog = ref(false);
    const currentClub = ref({});
    const logoDialogVisible = ref(false);
    const imageUrl = ref("");
    const provinces = ref([]);
    const searchedProvinces = ref([]);
    const provinceLoading = ref(false);
    const cities = ref([]);
    const searchedCities = ref([]);
    const cityLoading = ref(false);
    const counties = ref([]);
    const searchedCounties = ref([]);
    const countyLoading = ref(false);

    const onMountedHandle = async () => {
      fetchClubs();
      fetchProvince();
    };

    const fetchClubs = async () => {
      axios.post('/api/clubInfo/getClubs')
        .then(response => {
          clubs.value = response.data;
        })
        .catch(error => {
          showMessage('error', '获取俱乐部信息失败:' + error)
        });
    };

    const fetchProvince = () => {
      axios.get('api/baseData/getProvinces')
        .then((response) => {
          provinces.value = response.data;
        })
        .catch(error => {
          ElMessage.error('获取省份失败:' + error);
        });
    };
    const provinceSearch = (query) => {
      if (query) {
        provinceLoading.value = true
        setTimeout(() => {
          provinceLoading.value = false;
          searchedProvinces.value = provinces.value.filter((item) => {
            return item.regionName.toLowerCase().includes(query.toLowerCase()) || item.pyCode.toLowerCase().includes(query.toLowerCase())
          })
        }, 200)
      } else {
        searchedProvinces.value = []
      }
    };
    const provinceSelectChange = (regionCode) => {
      axios.get('api/baseData/getReginByParent?parentCode=' + regionCode)
        .then((response) => {
          cities.value = response.data;
          searchedCities.value = response.data;
        })
        .catch(error => {
          ElMessage.error('获取市失败:' + error);
        });
    };
    const citySearch = (query) => {
      if (query) {
        cityLoading.value = true
        setTimeout(() => {
          cityLoading.value = false;
          searchedCities.value = cities.value.filter((item) => {
            return item.regionName.toLowerCase().includes(query.toLowerCase()) || item.pyCode.toLowerCase().includes(query.toLowerCase())
          })
        }, 200)
      } else {
        searchedCities.value = []
      }
    };
    const citySelectChange = (regionCode) => {
      axios.get('api/baseData/getReginByParent?parentCode=' + regionCode)
        .then((response) => {
          counties.value = response.data;
          searchedCounties.value = response.data;
        })
        .catch(error => {
          ElMessage.error('获取县失败:' + error);
        });
    };
    const countySearch = (query) => {
      if (query) {
        provinceLoading.value = true
        setTimeout(() => {
          countyLoading.value = false;
          searchedCounties.value = counties.value.filter((item) => {
            return item.regionName.toLowerCase().includes(query.toLowerCase()) || item.pyCode.toLowerCase().includes(query.toLowerCase())
          })
        }, 200)
      } else {
        searchedCounties.value = []
      }
    };

    const saveClub = async () => {
      if (currentClub.value.clubid) {
        axios.post('/api/clubManagement/modifyClub', currentClub.value)
          .then(() => {
            fetchClubs();
            showDialog.value = false;
            currentClub.value = {};
            showMessage('success', '修改成功！');
            currentClub.value = {};
          })
          .catch(error => {
            showMessage('error', '修改俱乐部信息失败:' + error);
          });
      } else {
        currentClub.value.clubid = "";
        axios.post('/api/clubManagement/addClub', currentClub.value)
          .then(() => {
            fetchClubs();
            showDialog.value = false;
            showMessage('success', '添加成功！');
            currentClub.value = {};
          })
          .catch(error => {
            showMessage('error', '添加俱乐部信息失败:' + error)
          });
      }
    };

    const addClub = () => {
      showDialog.value = true;
      currentClub.value = {};
      if (!currentClub.value.validFlag) {
        currentClub.value.validFlag = "1";
      }
      showDialog.value = true;
    };

    const editClub = (club) => {
      currentClub.value = { ...club };
      showDialog.value = true;
    };

    const deleteClub = async (row) => {
      axios.post('/api/clubManagement/deleteClub?id=' + row.clubid)
        .then(() => {
          fetchClubs();
          showMessage('success', '删除成功！');
          currentClub.value = {};
        })
        .catch(error => {
          showMessage('error', '删除俱乐部信息失败:' + error)
        });
    };

    const editLogo = (club) => {
      currentClub.value = { ...club };
      logoDialogVisible.value = true;
    };

    const upLoadLogo = (param) => {
      // 创建 FormData 对象
      const formData = new FormData();
      // 将文件添加到 FormData 对象中
      formData.append('file', param.file);
      formData.append('clubid', currentClub.value.clubid);
      axios.post('api/clubManagement/uploadLogo', formData)
        .then(res => {
          showMessage('success', '上传成功:')
          handleLogoSuccess(res, param.file);
          return true;
        })
        .catch(error => {
          showMessage('error', '上传失败:' + error)
        });
    };

    const handleLogoSuccess = (response, uploadFile) => {
      imageUrl.value = URL.createObjectURL(uploadFile);
    };

    const beforeLogoUpload = (rawFile) => {
      if (rawFile.type !== 'image/jpeg') {
        ElMessage.error('头像图片必须是JPG格式')
        return false
      } else if (rawFile.size / 1024 / 1024 > 2) {
        ElMessage.error('头像图片不能超过2MB!')
        return false
      }
      return true
    };

    const showMessage = (type, msg) => {
      ElMessage({
        message: msg,
        type: type,
      })
    }

    const rowStyle = (rowParam) => {
      if (rowParam.row.validFlag === '0') {
        return 'background-color: #f0f0f0;'; // 设置背景颜色为灰色
      }
    }

    onMounted(onMountedHandle);

    return {
      clubs,
      showDialog,
      logoDialogVisible,
      imageUrl,
      currentClub,
      onMountedHandle,
      fetchClubs,
      saveClub,
      addClub,
      editClub,
      deleteClub,
      editLogo,
      handleLogoSuccess,
      upLoadLogo,
      beforeLogoUpload,
      showMessage,
      rowStyle,
      provinces,
      searchedProvinces,
      provinceLoading,
      cities,
      searchedCities,
      cityLoading,
      counties,
      searchedCounties,
      countyLoading,
      fetchProvince,
      provinceSearch,
      provinceSelectChange,
      citySearch,
      citySelectChange,
      countySearch
    };
  }
};
</script>

<style scoped>
.content {
  width: 80%;
  /* 设置宽度为父元素的80% */
  margin: auto;
  /* 水平居中对齐 */
  background-color: blueviolet;
}

.logo-uploader .logo {
  width: 178px;
  height: 178px;
  display: block;
}
</style>


<style>
.logo-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.logo-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.logo-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}

.content {
  width: 80%;
  /* 设置宽度为父元素的80% */
  margin: auto;
  /* 水平居中对齐 */
  background-color: blueviolet;
}
</style>
<template>
  <el-dialog title="用户登录" v-model="visible" width="30%" @close="handleClose">
    <el-form :model="loginForm" :rules="rules" ref="loginFormRef">
      <el-form-item label="用户" prop="username" @keyup.enter="handleLogin">
        <el-input v-model="loginForm.username"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password" @keyup.enter="handleLogin">
        <el-input type="password" v-model="loginForm.password" show-password></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="closeDialog">取消</el-button>
        <el-button type="primary" @click="handleLogin">登录</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import axios from 'axios';
import { ElMessage } from 'element-plus';

export default {
  name: 'LoginDialog',
  props: {
    isLoginDialogVisible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loginForm: {
        username: '',
        password: '',
      },
      rules: {
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      },
    };
  },
  computed: {
    visible: {
      get() {
        return this.isLoginDialogVisible;
      },
      set(value) {
        this.$emit('update-visible', value);
      },
    },
  },
  methods: {
    handleLogin() {
      this.$refs.loginFormRef.validate((valid) => {
        if (valid) {
          axios.post('/api/auth/login', this.loginForm)
            .then(response => {
              if (response.data) {
                const { accessToken, user } = response.data;
                localStorage.setItem('token', accessToken);
                localStorage.setItem('user', JSON.stringify(user));
                this.$emit('login-success', user);
                this.visible = false;
              } else {
                ElMessage.error('用户或密码错误');
              }

            })
            .catch(error => {
              ElMessage.error('用户或密码错误:+', error);
            });
        }
      });
    },
    closeDialog() {
      this.visible = false;
    },
    handleClose() {
      this.$emit('update:modelValue', false);
    },
  },
};
</script>

<style scoped>
.dialog-footer {
  text-align: right;
}
</style>
